import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER009 extends Component {

    render() {

        const izrezi = [];

        for (var i = 1; i <= 8; i++ ) {
            izrezi.push(
                <rect
                    key={uuidv4()}
                    x={(2.6*this.props.doorWidth/5)+i*(35)}
                    y={10}
                    width={20}
                    height={this.props.doorHeight-20}
                    style={{fill: "none", strokeWidth: "6.5", stroke: "rgba(0, 0, 0, 0.35)"}}
                />
            )
        };

        return (
            <React.Fragment>    
                { this.props.view === "outside" && 
                    <>
                        <g>
                            {izrezi}
                        </g>
                        {this.props.view === "outside" && 
                            this.props.handleDirection === "right" ? 
                                <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="end" x={this.props.doorWidth-90} y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                    {this.props.hisnaStevilka}
                                </text>
                            :
                                <text x="90" y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                    {this.props.hisnaStevilka}
                                </text>
                        }
                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER009;