import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER012 extends Component {

    render() {

        const izrezi = [];
        const izreziInox = [];

        for (var e = 1; e <= 5; e++ ) {
            izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={300}
                        y={(this.props.doorHeight/2)-3*250+e*250}
                        width={this.props.doorWidth-310}
                        height={7}
                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                    />
                    <rect
                        x={300}
                        y={(this.props.doorHeight/2)-3*250+e*250+15}
                        width={this.props.doorWidth-310}
                        height={7}
                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                    />
                </React.Fragment>
            )
            izreziInox.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        className="izrez-inox"
                        x={300}
                        y={(this.props.doorHeight/2)-3*250+e*250+20}
                        width={this.props.doorWidth-310}
                        height={17}
                    />
                </React.Fragment>
            )
        };

        return (
            <React.Fragment>
                <clipPath id="stekloGlavno">
                    <path
                        transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                        d="M216 1271C103.12 1114.65 33.5 900.41 33.5 664.05c0-238 70.59-453.57 184.86-610.17"
                        style={{fill: "none", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                    />
                </clipPath>
                <clipPath id="stekloIzrezi">
                    <rect
                        x={300}
                        y={(this.props.doorHeight/2)-3*250+250}
                        width={70}
                        height={15}
                        rx="10"
                    />
                    <rect
                        x={220}
                        y={(this.props.doorHeight/2)-3*250+2*250}
                        width={155}
                        height={15}
                        rx="10"
                    />
                    <rect
                        x={200}
                        y={(this.props.doorHeight/2)-3*250+3*250}
                        width={170}
                        height={15}
                        rx="10"
                    />
                    <rect
                        x={220}
                        y={(this.props.doorHeight/2)-3*250+4*250}
                        width={155}
                        height={15}
                        rx="10"
                    />
                    <rect
                        x={300}
                        y={(this.props.doorHeight/2)-3*250+5*250}
                        width={70}
                        height={15}
                        rx="10"
                    />
                </clipPath>
                <clipPath id="stekloIzreziPokoncno">
                    <rect
                        x={365}
                        y={this.props.doorHeight/2-1250/2}
                        width={20}
                        height={1250}
                        rx="10"
                    />
                </clipPath>

                {this.props.view === "outside" && 
                    <React.Fragment>
                        <rect
                            x={360}
                            y={10}
                            width={7}
                            height={this.props.doorHeight-10}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={360+13}
                            y={10}
                            width={7}
                            height={this.props.doorHeight-10}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                        {this.props.inoxAplikacije ? izreziInox : izrezi}
                    </React.Fragment>
                }
                
                {this.props.glassModel === "12-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzreziPokoncno)", clipPath: "url(#stekloIzreziPokoncno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }
                {this.props.glassModel === "12-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzreziPokoncno)", clipPath: "url(#stekloIzreziPokoncno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }

                {this.props.glassModel === "12-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }
                {this.props.glassModel === "12-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }

                {this.props.glassModel === "12-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }
                {this.props.glassModel === "12-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }

                {this.props.glassModel === "12-4" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }
                {this.props.glassModel === "12-4" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <path
                            transform={`translate(165,${this.props.doorHeight/2-1332/2})`}
                            d="M212.9 1272.33C101.84 1116.39 33.5 903.91 33.5 669.73c0-236.07 69.5-450.08 182.11-606.35V7.22C84.28 174.4 2.5 410 2.5 671.13c0 261.71 82.17 497.85 214 665.09v-63.89"
                            style={{fill: "#ccc", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                    </React.Fragment>
                }

                {this.props.view === "outside" &&
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="end"x={this.props.doorWidth-90} y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text x="90" y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    )
                }
                    
            </React.Fragment>
        );
    }
}

export default CER012;