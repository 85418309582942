import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER018 extends Component {

    render() {

        const izrezi = []
        const izreziSteklo = []
        var visinaStekla = 1400;
        this.props.doorHeight >= 2300 ? visinaStekla = 1600 : visinaStekla = 1400

        for (var e = 1; e <= 5; e++ ) {
            izreziSteklo.push(
                <rect
                    key={uuidv4()}
                    x={(255)}
                    y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)}
                    width={195}
                    height={20}
                    rx="10"
                />
            )
            this.props.inoxAplikacije ?
                (izrezi.push(
                    <rect
                        key={uuidv4()}
                        className="izrez-inox"
                        x={450}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)}
                        width={this.props.doorWidth-455}
                        height={20}
                    />
                ))
            :
                (izrezi.push(
                    <React.Fragment key={uuidv4()}>
                        <rect
                            x={450}
                            y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)}
                            width={this.props.doorWidth-455}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={450}
                            y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)+18}
                            width={this.props.doorWidth-455}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                    </React.Fragment>
                ))
        }

        return (
            <React.Fragment>
                <clipPath id="stekloGlavno">
                    <rect
                        x={(250)}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)}
                        width={200}
                        height={visinaStekla}
                        rx="10"
                    />
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {izreziSteklo}
                </clipPath>

                { this.props.view === "outside" && izrezi }

                {this.props.glassModel === "18-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "18-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "18-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "18-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "18-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "18-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.view === "outside" &&
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="end" x={this.props.doorWidth-20} y={175} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text textAnchor="end" x={this.props.doorWidth-20} y={175} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    )
                }
            </React.Fragment>
        );
    }
}

export default CER018;