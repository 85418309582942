import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER010 extends Component {

    render() {

        const stekla = []

        for (var e = 1; e <= 4; e++ ) {
            stekla.push(
                <rect
                    key={uuidv4()}
                    x={this.props.doorWidth/2-350/2+5}
                    y={(this.props.doorHeight-1400)/2+(e)*(264+20)}
                    width={350-5}
                    height={20}
                />
            )
        }

        return (
            <React.Fragment>
                
                { this.props.view === "outside" &&
                    <React.Fragment>
                        <rect
                            x={this.props.doorWidth/2-12.5}
                            y={5}
                            width={7}
                            height={(this.props.doorHeight-1400)/2-20-10}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={this.props.doorWidth/2+18-12.5}
                            y={5}
                            width={7}
                            height={(this.props.doorHeight-1400)/2-20-10}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                        <rect
                            x={this.props.doorWidth/2-12.5}
                            y={(this.props.doorHeight+1440)/2}
                            width={7}
                            height={(this.props.doorHeight-1400)/2-20-10}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={this.props.doorWidth/2+18-12.5}
                            y={(this.props.doorHeight+1440)/2}
                            width={7}
                            height={(this.props.doorHeight-1400)/2-20-10}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                        <rect
                            x={5}
                            y={(this.props.doorHeight-1400)/2+264+20}
                            width={this.props.doorWidth/2-390/2-5}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={5}
                            y={(this.props.doorHeight-1400)/2+264+20+18}
                            width={this.props.doorWidth/2-390/2-5}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                        <rect
                            x={this.props.doorWidth-(this.props.doorWidth/2-390/2)}
                            y={(this.props.doorHeight-1400)/2+264+20}
                            width={this.props.doorWidth/2-390/2-5}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            x={this.props.doorWidth-(this.props.doorWidth/2-390/2)}
                            y={(this.props.doorHeight-1400)/2+264+20+18}
                            width={this.props.doorWidth/2-390/2-5}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                    </React.Fragment>
                }
                <clipPath id="stekloGlavno">
                    <rect
                        x={this.props.doorWidth/2-350/2}
                        y={(this.props.doorHeight-1400)/2}
                        width={350}
                        height={1400}
                        rx="10"
                    />   
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {stekla}
                </clipPath>
                <clipPath id="stekloZgornjeProsojno">
                    <rect
                        x={this.props.doorWidth/2-350/2}
                        y={(this.props.doorHeight-1400)/2}
                        width={350}
                        height={290}
                        rx="10"
                    />
                </clipPath>

                <rect
                    x={this.props.doorWidth/2-390/2}
                    y={(this.props.doorHeight-1400)/2-20}
                    width={350+40}
                    height={1400+40}
                    style={{stroke: "#000", strokeWidth: 2, fill: "none"}}
                    rx="10"
                />
                
                
                {this.props.glassModel === "10-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "10-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "10-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloZgornjeProsojno)", clipPath: "url(#stekloZgornjeProsojno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "10-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloZgornjeProsojno)", clipPath: "url(#stekloZgornjeProsojno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "10-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "10-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "10-4" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "10-4" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.view === "outside" &&
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text textAnchor="end" x={this.props.doorWidth-20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                    </text>
                    )
                }

            </React.Fragment>
        );
    }
}

export default CER010;