import React, { Component } from 'react';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER002 extends Component {

    render() {

        return (
            <React.Fragment>
                <clipPath id="fillGlassPath">
                    <path
                        id="steklo"
                        d={`M${330},${1537.5}c0,0,124.3-324.8,124.3-682.8c0-328.9-124.3-693.4-124.3-693.4h-80v1376.1L${330},${1537.5} L${330},${1537.5}z`}
                    />
                </clipPath>
                <clipPath id="fillIzrezi">
                    <rect
                        x={250+5}
                        y={327.1}
                        width={125}
                        height={20}
                    />
                    <rect
                        x={250+5}
                        y={844.4} 
                        width={200}
                        height={20}
                    />
                    <rect
                        x={250+5}
                        y={1359.4}
                        width={125}
                        height={20}
                    />
                </clipPath>

                { this.props.view === "outside" ?
                    <>
                        <g>
                            <g transform={`translate(0 ${this.props.razlikaVisine * (this.props.newyf) / 2})`}>
                                {this.props.inoxAplikacije ? 
                                    <g style={{isolation: "isolate"}}>
                                        <rect
                                            x={250+10}
                                            y={327.1}
                                            className="izrez-inox"
                                            width={this.props.doorWidth-250-10}
                                            height="20"
                                        />
                                        <rect
                                            x={250+10}
                                            y={844.4} 
                                            className="izrez-inox"
                                            width={this.props.doorWidth-250-10}
                                            height="20"
                                        />
                                        <rect
                                            x={250+10}
                                            y={1359.4}
                                            className="izrez-inox"
                                            width={this.props.doorWidth-250-10}
                                            height="20"
                                        />
                                    </g>
                                :
                                    <g style={{isolation: "isolate"}}>
                                        <rect
                                            x={250+10}
                                            y={327.1}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                        />
                                        <rect
                                            x={250+10}
                                            y={327.1+14}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                        />
                                        <rect
                                            x={250+10}
                                            y={844.4}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                        />
                                        <rect
                                            x={250+10}
                                            y={844.4+14}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                        />
                                        <rect
                                            x={250+10}
                                            y={1359.4}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                        />
                                        <rect
                                            x={250+10}
                                            y={1359.4+14}
                                            width={this.props.doorWidth-250-10}
                                            height={7}
                                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                        />
                                    </g>
                                }
                                {this.props.glassModel === "02-1" && this.props.view === "outside" &&
                                    <React.Fragment>
                                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                                        <image style={{WebkitClipPath: "url(#fillIzrezi)", clipPath: "url(#fillIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                                    </React.Fragment>
                                }
                                {this.props.glassModel === "02-2" && this.props.view === "outside" &&
                                    <React.Fragment>
                                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                                    </React.Fragment>
                                }
                                {this.props.glassModel === "02-3" && this.props.view === "outside" &&
                                    <React.Fragment>
                                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                                    </React.Fragment>
                                }
                            </g>
                        </g>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={175} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="end" x={this.props.doorWidth-20} y={175} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                        
                    </>
                :
                    <g transform={`translate(0 ${this.props.razlikaVisine * (this.props.newyf) / 2})`}>
                        {this.props.glassModel === "02-1" && this.props.view === "inside" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                                <image style={{WebkitClipPath: "url(#fillIzrezi)", clipPath: "url(#fillIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                            </React.Fragment>
                        }
                        {this.props.glassModel === "02-2" && this.props.view === "inside" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                            </React.Fragment>
                        }
                        {this.props.glassModel === "02-3" && this.props.view === "inside" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                            </React.Fragment>
                        }
                    </g>
                }
            </React.Fragment>
        );
    }
}

export default CER002;