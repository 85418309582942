import React, { Component } from 'react';
import stekloProsojnoNotriTop from "../glass/prosojno-steklo-notri-nr.jpg";
import stekloProsojnoZunajTop from "../glass/prosojno-steklo-zunaj-nr.jpg";

class FrameGlassTop extends Component {

    render() {

        return (
            <React.Fragment>
                <clipPath id="glassTopSVG">
                    <rect
                        x={this.props.x}
                        y={this.props.y}
                        width={this.props.frameWidth}
                        height={this.props.glassDimensions}
                    />
                </clipPath>

                { this.props.view === "outside" ?
                    
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#glassTopSVG)", clipPath: "url(#glassTopSVG)"}} height="100%" xlinkHref={stekloProsojnoNotriTop} />
                    </React.Fragment>
                :
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#glassTopSVG)", clipPath: "url(#glassTopSVG)"}} height="100%" xlinkHref={stekloProsojnoZunajTop} />
                    </React.Fragment>

                }
            </React.Fragment>
        );
    }
}

export default FrameGlassTop;