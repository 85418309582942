import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER007 extends Component {

    render() {

        const izrezi = [];

        for (var i = 1; i <= 4; i++ ) {
            izrezi.push(
                (this.props.doorHeight < 2270 || this.props.doorHeight > 2450) ?
                    <React.Fragment key={uuidv4()}>
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)+18}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                    </React.Fragment>
                :
                    <React.Fragment key={uuidv4()}>
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(0, 0, 0, 0.35)"}}
                        />
                        <rect
                            key={uuidv4()}
                            x={10}
                            y={i*(this.props.doorHeight/5)+18}
                            width={this.props.doorWidth-20}
                            height={7}
                            style={{fill: "rgba(246, 246, 246, 0.35)"}}
                        />
                    </React.Fragment>
            )
        };

        return (
            <React.Fragment>    
                { this.props.view === "outside" && 
                    <>
                        <g>
                            {izrezi}
                        </g>
                        {this.props.view === "outside" && 
                            this.props.handleDirection === "right" ? 
                                <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                    {this.props.hisnaStevilka}
                                </text>
                            :
                                <text textAnchor="end" x={this.props.doorWidth-20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                    {this.props.hisnaStevilka}
                            </text>
                        }
                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER007;