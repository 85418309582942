import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER020 extends Component {

    render() {

        const steklaIzrezi = [];

        for (var e = 1; e <= 4; e++ ) {
            steklaIzrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={250+(this.props.doorWidth-600)/2+5}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1600)/2 : (this.props.doorHeight-1600)/2)+(e)*(325.5)}
                        width={350-10}
                        height={15}
                    />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <clipPath id="stekloGlavno">
                    <rect
                        x={250+(this.props.doorWidth-600)/2}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1600)/2 : (this.props.doorHeight-1600)/2)}
                        width={350}
                        height={1600}
                        rx="10"
                    />
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {steklaIzrezi}
                </clipPath>
                
                { this.props.view === "outside" &&
                    <rect
                        fill={`url(#${this.props.colorDecor2})`}
                        x={5}
                        y={5}
                        width={250}
                        height={this.props.doorHeight-10}
                    />
                }

                {this.props.glassModel === "20-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "20-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "20-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "20-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "20-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "20-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                { 
                    this.props.view === "outside" &&
                    <React.Fragment>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER020;