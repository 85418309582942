import zunaj_A1_1 from "./assets/images/ozadje-zunanje/1_A1.jpg";
import zunaj_A2_1 from "./assets/images/ozadje-zunanje/1_A2_l.jpg";
import zunaj_A3_1 from "./assets/images/ozadje-zunanje/1_A3.jpg";
import zunaj_B1_1 from "./assets/images/ozadje-zunanje/1_B1_l.jpg";
import zunaj_B3_1 from "./assets/images/ozadje-zunanje/1_B3_l.jpg";
import zunaj_C1_1 from "./assets/images/ozadje-zunanje/1_C1.jpg";
import zunaj_C2_1 from "./assets/images/ozadje-zunanje/1_C2_l.jpg";
import zunaj_C3_1 from "./assets/images/ozadje-zunanje/1_C3.jpg";
import zunaj_A1_2 from "./assets/images/ozadje-zunanje/2_A1.jpg";
import zunaj_A2_2 from "./assets/images/ozadje-zunanje/2_A2_l.jpg";
import zunaj_A3_2 from "./assets/images/ozadje-zunanje/2_A3.jpg";
import zunaj_B1_2 from "./assets/images/ozadje-zunanje/2_B1_l.jpg";
import zunaj_B3_2 from "./assets/images/ozadje-zunanje/2_B3_l.jpg";
import zunaj_C1_2 from "./assets/images/ozadje-zunanje/2_C1.jpg";
import zunaj_C2_2 from "./assets/images/ozadje-zunanje/2_C2_l.jpg";
import zunaj_C3_2 from "./assets/images/ozadje-zunanje/2_C3.jpg";
import notri_A1_1 from "./assets/images/ozadje-notranje/1_A1.jpg";
import notri_A2_1 from "./assets/images/ozadje-notranje/1_A2_l.jpg";
import notri_A3_1 from "./assets/images/ozadje-notranje/1_A3.jpg";
import notri_B1_1 from "./assets/images/ozadje-notranje/1_B1_l.jpg";
import notri_B3_1 from "./assets/images/ozadje-notranje/1_B3_l.jpg";
import notri_C1_1 from "./assets/images/ozadje-notranje/1_C1.jpg";
import notri_C2_1 from "./assets/images/ozadje-notranje/1_C2_l.jpg";
import notri_C3_1 from "./assets/images/ozadje-notranje/1_C3.jpg";
import notri_A1_2 from "./assets/images/ozadje-notranje/2_A1.jpg";
import notri_A2_2 from "./assets/images/ozadje-notranje/2_A2_l.jpg";
import notri_A3_2 from "./assets/images/ozadje-notranje/2_A3.jpg";
import notri_B1_2 from "./assets/images/ozadje-notranje/2_B1_l.jpg";
import notri_B3_2 from "./assets/images/ozadje-notranje/2_B3_l.jpg";
import notri_C1_2 from "./assets/images/ozadje-notranje/2_C1-2_l.jpg";
import notri_C2_2 from "./assets/images/ozadje-notranje/2_C2-2_l.jpg";
import notri_C3_2 from "./assets/images/ozadje-notranje/2_C3-2_l.jpg";
import notri_A1_3 from "./assets/images/ozadje-notranje/3_A1.jpg";
import notri_A2_3 from "./assets/images/ozadje-notranje/3_A2_l.jpg";
import notri_A3_3 from "./assets/images/ozadje-notranje/3_A3.jpg";
import notri_B1_3 from "./assets/images/ozadje-notranje/3_B1.jpg";
import notri_B3_3 from "./assets/images/ozadje-notranje/3_B3.jpg";
import notri_C1_3 from "./assets/images/ozadje-notranje/3_C1.jpg";
import notri_C2_3 from "./assets/images/ozadje-notranje/3_C2_l.jpg";
import notri_C3_3 from "./assets/images/ozadje-notranje/3_C3.jpg";
import belZid from "./assets/images/wall.png";
import opeka from "./assets/images/brick-wall.jpg";
import tlaZunaj from "./assets/images/tla-zunaj-1.jpg";
import tlaNotri from "./assets/images/tla-notri-1.jpg";

export const ozadjeZunanje1 = {
    a1: {
        backgroundImage: `url(${zunaj_A1_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundImage: `url(${zunaj_B1_1})`,
        backgroundSize: "573px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundImage: `url(${zunaj_C1_1})`,
        backgroundSize: "298px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundImage: `url(${zunaj_A2_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundImage: `url(${zunaj_C2_1})`,
        backgroundSize: "298px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundImage: `url(${zunaj_A3_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundImage: `url(${zunaj_B3_1})`,
        backgroundSize: "491px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundImage: `url(${zunaj_C3_1})`,
        backgroundSize: "298px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeZunanje2 = {
    a1: {
        backgroundImage: `url(${zunaj_A1_2})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundImage: `url(${zunaj_B1_2})`,
        backgroundSize: "543px",
        backgroundPosition: "50% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundImage: `url(${zunaj_C1_2})`,
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundImage: `url(${zunaj_A2_2})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundImage: `url(${zunaj_C2_2})`,
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundImage: `url(${zunaj_A3_2})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundImage: `url(${zunaj_B3_2})`,
        backgroundSize: "525px",
        backgroundPosition: "50% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundImage: `url(${zunaj_C3_2})`,
        backgroundSize: "375px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeBelZid = {
    zid: {
        backgroundImage: `url(${belZid})`,
    },
    belZid: {
        backgroundImage: `url(${belZid})`,
    },
    tlaZunaj: {
        backgroundImage: `url(${tlaZunaj})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    tlaNotri: {
        backgroundImage: `url(${tlaNotri})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    a1: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundSize: "363px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundSize: "350px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundSize: "375px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeSivZid = {
    zid: {
        backgroundImage: `url(${belZid})`,
        backgroundColor: "#000000c7"
    },
    belZid: {
        backgroundImage: `url(${belZid})`,
    },
    tlaZunaj: {
        backgroundImage: `url(${tlaZunaj})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    tlaNotri: {
        backgroundImage: `url(${tlaNotri})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    a1: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundSize: "363px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundSize: "350px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundSize: "375px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeOpeka = {
    zid: {
        backgroundImage: `url(${opeka})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 100%"
    },
    belZid: {
        backgroundImage: `url(${belZid})`,
    },
    tlaZunaj: {
        backgroundImage: `url(${tlaZunaj})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    tlaNotri: {
        backgroundImage: `url(${tlaNotri})`,
        backgroundPosition: "50% 0%",
        backgroundSize: "cover"
    },
    a1: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundSize: "363px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundSize: "350px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundSize: "375px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeNotranje1 = {
    a1: {
        backgroundImage: `url(${notri_A1_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundImage: `url(${notri_B1_1})`,
        backgroundSize: "333px",
        backgroundPosition: "50% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundImage: `url(${notri_C1_1})`,
        backgroundSize: "245px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundImage: `url(${notri_A2_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundImage: `url(${notri_C2_1})`,
        backgroundSize: "245px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundImage: `url(${notri_A3_1})`,
        backgroundSize: "380px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundImage: `url(${notri_B3_1})`,
        backgroundSize: "341px",
        backgroundPosition: "50% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundImage: `url(${notri_C3_1})`,
        backgroundSize: "245px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};
export const ozadjeNotranje2 = {
    a1: {
        backgroundImage: `url(${notri_A1_2})`,
        backgroundSize: "470px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundImage: `url(${notri_B1_2})`,
        backgroundSize: "617px",
        backgroundPosition: "50% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundImage: `url(${notri_C1_2})`,
        backgroundSize: "470px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundImage: `url(${notri_A2_2})`,
        backgroundSize: "470px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundImage: `url(${notri_C2_2})`,
        backgroundSize: "470px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundImage: `url(${notri_A3_2})`,
        backgroundSize: "470px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundImage: `url(${notri_B3_2})`,
        backgroundSize: "630px",
        backgroundPosition: "50% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundImage: `url(${notri_C3_2})`,
        backgroundSize: "470px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};

export const ozadjeNotranje3 = {
    a1: {
        backgroundImage: `url(${notri_A1_3})`,
        backgroundSize: "400px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    b1: {
        backgroundImage: `url(${notri_B1_3})`,
        backgroundSize: "403px",
        backgroundPosition: "50% 100%",
        backgroundRepeat: "repeat-x"
    },
    c1: {
        backgroundImage: `url(${notri_C1_3})`,
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a2: {
        backgroundImage: `url(${notri_A2_3})`,
        backgroundSize: "400px",
        backgroundPosition: "100% 100%",
        backgroundRepeat: "no-repeat"
    },
    c2: {
        backgroundImage: `url(${notri_C2_3})`,
        backgroundSize: "380px",
        backgroundPosition: "0% 100%",
        backgroundRepeat: "no-repeat"
    },
    a3 : {
        backgroundImage: `url(${notri_A3_3})`,
        backgroundSize: "402px",
        backgroundPosition: "100% 0%",
        backgroundRepeat: "no-repeat"
    },
    b3: {
        backgroundImage: `url(${notri_B3_3})`,
        backgroundSize: "390px",
        backgroundPosition: "50% 0%",
        backgroundRepeat: "repeat-x"
    },
    c3: {
        backgroundImage: `url(${notri_C3_3})`,
        backgroundSize: "380px",
        backgroundPosition: "0% 0%",
        backgroundRepeat: "no-repeat"
    }
};
