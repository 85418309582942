import React, { Component } from 'react';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";
import stekloRefleksivno from "../../glass/refleksivno-steklo.jpg";
import { v4 as uuidv4 } from 'uuid';

class CER015 extends Component {

    render() {

        const stekla = [];

        for (var e = 1; e <= 4; e++ ) {
            stekla.push(
                <rect
                    key={uuidv4()}
                    x={this.props.doorWidth-445+5}
                    y={((this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*285)}
                    width={250-10}
                    height={20}
                    rx="5"
                />
            )
        };

        return (
            <React.Fragment>
                <clipPath id="stekloRefleksivno">
                    <rect
                        x={this.props.doorWidth-500}
                        y={10}
                        width={360}
                        height={this.props.doorHeight-10}
                    />
                </clipPath>
                <clipPath id="stekloGlavno">
                    <rect
                        x={this.props.doorWidth-445}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+300}
                        width={250}
                        height={1400-300}
                        rx="1"
                    />
                </clipPath>
                <clipPath id="stekloZgoraj">
                    <rect
                        x={this.props.doorWidth-445}
                        y={this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2}
                        width={250}
                        height={300}
                        rx="1"
                    />
                </clipPath>
                <clipPath id="stekloIzrezZgoraj">
                    <rect
                        x={this.props.doorWidth-445+115}
                        y={this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2}
                        width={20}
                        height={300}
                        rx="1"
                    />
                </clipPath>
                <clipPath id="stekloIzrezSpodaj">
                    <rect
                        x={this.props.doorWidth-445+115}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+300}
                        width={20}
                        height={1400-300}
                        rx="1"
                    />
                </clipPath>
                <clipPath id="stekloGlavnoCelo">
                    <rect
                        x={this.props.doorWidth-445}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)}
                        width={250}
                        height={1400}
                        rx="1"
                    />
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {stekla}
                </clipPath>
                
                { this.props.view === "outside" &&
                    (this.props.inoxAplikacije ?
                        <React.Fragment>
                            <rect
                                className="izrez-inox"
                                x={10}
                                y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+280}
                                width={this.props.doorWidth-20}
                                height={25}
                            />
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <rect
                                x={10}
                                y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+280}
                                width={this.props.doorWidth-20}
                                height={7}
                                style={{fill: "rgba(0, 0, 0, 0.35)"}}
                            />
                            <rect
                                x={10}
                                y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+15+280}
                                width={this.props.doorWidth-20}
                                height={7}
                                style={{fill: "rgba(246, 246, 246, 0.35)"}}
                            />
                        </React.Fragment>
                    )
                }

                {this.props.glassModel === "15-1" && this.props.view === "outside" &&
                    <React.Fragment>                        
                        <image style={{WebkitClipPath: "url(#stekloRefleksivno)", clipPath: "url(#stekloRefleksivno)"}} height="100%" xlinkHref={stekloRefleksivno} />
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloZgoraj)", clipPath: "url(#stekloZgoraj)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezZgoraj)", clipPath: "url(#stekloIzrezZgoraj)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezSpodaj)", clipPath: "url(#stekloIzrezSpodaj)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "15-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloZgoraj)", clipPath: "url(#stekloZgoraj)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezZgoraj)", clipPath: "url(#stekloIzrezZgoraj)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezSpodaj)", clipPath: "url(#stekloIzrezSpodaj)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "15-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloRefleksivno)", clipPath: "url(#stekloRefleksivno)"}} height="100%" xlinkHref={stekloRefleksivno} />
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "15-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "15-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloRefleksivno)", clipPath: "url(#stekloRefleksivno)"}} height="100%" xlinkHref={stekloRefleksivno} />
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "15-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "15-4" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloRefleksivno)", clipPath: "url(#stekloRefleksivno)"}} height="100%" xlinkHref={stekloRefleksivno} />
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "15-4" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavnoCelo)", clipPath: "url(#stekloGlavnoCelo)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                

                {this.props.view === "outside" &&
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="end" x={this.props.doorWidth-90} y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text x="90" y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    )
                }                
            </React.Fragment>
        );
    }
}

export default CER015;