import React, { Component } from 'react';
import stekloProsojnoNotriRight from "../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotriRight from "../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunajRight from "../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunajRight from "../glass/satinirano-steklo-zunaj-nr.jpg";
import { v4 as uuidv4 } from 'uuid';

class FrameGlassRight extends Component {

    render() {

        var newyf = this.props.doorHeight/1800;
        var razlikaVisine = this.props.doorHeight - 1800;

        const rcer003 = [];

        for (let e = 0; e <= 4; e++ ) {
            (rcer003.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+400+e*((this.props.doorHeight-900)/4)}
                    width={this.props.glassDimensions}
                    height={100}
                />
            ))
        }

        const rcer005 = [];

        for (let i = 1; i <= 12; i++ ) {
            rcer005.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+50+i*((this.props.doorHeight-((this.props.inoxOdbojnaLetev && this.props.view === "outside") ? 150 : 100))/13)}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        }

        const rcer006 = [];

        for (let i = 1; i <= 4; i++ ) {
            rcer006.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={i*(this.props.doorHeight/5)+100}
                    width={this.props.glassDimensions}
                    height={17}
                />
            )
        };

        const rcer011 = [];

        for (let e = 1; e <= 4; e++ ) {
            rcer011.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+(this.props.doorHeight-1400)/2+(e)*(264+20)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const rcer010 = [];

        for (let e = 1; e <= 4; e++ ) {
            rcer010.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+30+(this.props.doorHeight-1400)/2+(e)*(264+20)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        }

        const rcer012 = [];

        for (let e = 1; e <= 5; e++ ) {
            rcer012.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+(this.props.doorHeight/2)-3*250+e*250+20}
                    width={this.props.glassDimensions}
                    height={17}
                />
            )
        };

        const rcer014 = [];

        for (let i = 1; i <= 5; i++ ) {
            rcer014.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+30+i*(this.props.doorHeight/6)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const rcer016 = [];

        for (let e = 1; e <= 5; e++ ) {
            rcer016.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*233}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        }

        const rcer017 = [];

        for (var e = 1; e <= 6; e++ ) {
            rcer017.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.x}
                        y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1500)/2 : (this.props.doorHeight-1500)/2)+e*225}
                        width={this.props.glassDimensions}
                        height={25}
                    />
                </React.Fragment>
            )
        };

        const rcer018 = [];
        var visinaStekla = 1400;
        this.props.doorHeight >= 2300 ? visinaStekla = 1600 : visinaStekla = 1400

        for (let e = 1; e <= 5; e++ ) {
            rcer018.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        };

        const rcer019 = [];

        for (let e = 0; e <= 4; e++ ) {
            rcer019.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+440+e*((this.props.doorHeight-900)/4)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const rcer020 = [];

        for (let e = 1; e <= 4; e++ ) {
            rcer020.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1600)/2 : (this.props.doorHeight-1600)/2)+(e)*(325.5)}
                    width={this.props.glassDimensions}
                    height={15}
                />
            )
        };

        const rcer022 = [];

        for (let e = 1; e <= 4; e++ ) {
            rcer022.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        }

        return (
            <React.Fragment>
                <clipPath id="Rf_main">
                    <rect
                        x={this.props.x}
                        y={this.props.y}
                        width={this.props.glassDimensions}
                        height={this.props.doorHeight}
                    />
                </clipPath>
                <clipPath id="RCER-001">
                    <rect
                        x={this.props.x}
                        y={this.props.y+130}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                    <rect
                        x={this.props.x}
                        y={this.props.y+(this.props.doorHeight/2)}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                    <rect
                        x={this.props.x}
                        y={this.props.y+this.props.doorHeight-130}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                </clipPath>
                <clipPath id="RCER-002">
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+327.1}
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+844.4} 
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+1359.4}
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                </clipPath>
                <clipPath id="RCER-003">
                    {rcer003}
                </clipPath>
                <clipPath id="RCER-005">
                    {rcer005}
                </clipPath>
                <clipPath id="RCER-006">
                    {rcer006}
                </clipPath>
                <clipPath id="RCER-010">
                    {rcer010}
                </clipPath>
                <clipPath id="RCER-011">
                    {rcer011}
                </clipPath>
                <clipPath id="RCER-012">
                    {rcer012}
                </clipPath>
                <clipPath id="RCER-014">
                    {rcer014}
                </clipPath>
                <clipPath id="RCER-015">
                    <rect
                        x={this.props.x}
                        y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+280}
                        width={this.props.glassDimensions}
                        height={25}
                    />
                </clipPath>
                <clipPath id="RCER-016">
                    {rcer016}
                </clipPath>
                <clipPath id="RCER-017">
                    {rcer017}
                </clipPath>
                <clipPath id="RCER-018">
                    {rcer018}
                </clipPath>
                <clipPath id="RCER-019">
                    {rcer019}
                </clipPath>
                <clipPath id="RCER-020">
                    {rcer020}
                </clipPath>
                <clipPath id="RCER-022">
                    {rcer022}
                </clipPath>

                { this.props.view === "outside" ?
                    <>                                
                        {this.props.glassFrameModel === "f_satinirano" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_prosojno" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_01" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-001)", clipPath: "url(#RCER-001)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_02" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-002)", clipPath: "url(#RCER-002)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_03" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-003)", clipPath: "url(#RCER-003)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_05" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-005)", clipPath: "url(#RCER-005)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {(this.props.glassFrameModel === "f_06" || this.props.glassFrameModel === "f_07")  &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-006)", clipPath: "url(#RCER-006)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_10" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-010)", clipPath: "url(#RCER-010)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_11" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-011)", clipPath: "url(#RCER-011)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_12" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-012)", clipPath: "url(#RCER-012)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_14" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-014)", clipPath: "url(#RCER-014)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_15" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-015)", clipPath: "url(#RCER-015)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_16" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-016)", clipPath: "url(#RCER-016)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_17" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-017)", clipPath: "url(#RCER-017)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_18" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-018)", clipPath: "url(#RCER-018)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_19" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-019)", clipPath: "url(#RCER-019)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_20" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-020)", clipPath: "url(#RCER-020)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_22" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-022)", clipPath: "url(#RCER-022)"}} height="100%" xlinkHref={stekloProsojnoNotriRight} />
                            </React.Fragment>
                        }
                    </>
                :
                    <>
                        {this.props.glassFrameModel === "f_satinirano" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_prosojno" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_01" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-001)", clipPath: "url(#RCER-001)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_02" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-002)", clipPath: "url(#RCER-002)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_03" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-003)", clipPath: "url(#RCER-003)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_05" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-005)", clipPath: "url(#RCER-005)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {(this.props.glassFrameModel === "f_06" || this.props.glassFrameModel === "f_07")  &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-006)", clipPath: "url(#RCER-006)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_10" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-010)", clipPath: "url(#RCER-010)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_11" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-011)", clipPath: "url(#RCER-011)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_12" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-012)", clipPath: "url(#RCER-012)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_14" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-014)", clipPath: "url(#RCER-014)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_15" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-015)", clipPath: "url(#RCER-015)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_16" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-016)", clipPath: "url(#RCER-016)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_17" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-017)", clipPath: "url(#RCER-017)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_18" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-018)", clipPath: "url(#RCER-018)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_19" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-019)", clipPath: "url(#RCER-019)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_20" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-020)", clipPath: "url(#RCER-020)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_22" &&
                            <React.Fragment>
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#Rf_main)", clipPath: "url(#Rf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajRight} />
                                <image x={this.props.x-this.props.glassDimensions} style={{WebkitClipPath: "url(#RCER-022)", clipPath: "url(#RCER-022)"}} height="100%" xlinkHref={stekloProsojnoZunajRight} />
                            </React.Fragment>
                        }
                    </>
                }
            </React.Fragment>
        );
    }
}

export default FrameGlassRight;