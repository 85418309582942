import React, { Component } from 'react';
import stekloRefleksivno from "../../glass/refleksivno-steklo.jpg";
import { v4 as uuidv4 } from 'uuid';

class CER017 extends Component {

    render() {

        const izrezi = [];

        for (var e = 1; e <= 6; e++ ) {
            this.props.inoxAplikacije ?
                (izrezi.push(
                    <React.Fragment key={uuidv4()}>
                        <rect
                            className="izrez-inox"
                            x={225}
                            y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1500)/2 : (this.props.doorHeight-1500)/2)+e*225}
                            width={this.props.doorWidth-225}
                            height={25}
                        />
                    </React.Fragment>
                ))
            :
            (izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={225}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1500)/2 : (this.props.doorHeight-1500)/2)+e*225}
                        width={this.props.doorWidth-225}
                        height={7}
                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                    />
                    <rect
                        x={225}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1500)/2 : (this.props.doorHeight-1500)/2)+18+e*225}
                        width={this.props.doorWidth-225}
                        height={7}
                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                    />
                </React.Fragment>
            ))
        };

        return (
            <React.Fragment>    
                <clipPath id="fill-refleksivno-steklo">
                    <rect
                        id="steklo-refleksivno"
                        x={10}
                        y={10}
                        width={this.props.doorWidth-10}
                        height={this.props.doorHeight-10}
                    />
                </clipPath>
                { this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fill-refleksivno-steklo)", clipPath: "url(#fill-refleksivno-steklo)"}} height="100%" xlinkHref={stekloRefleksivno} />
                        {izrezi}
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        :
                            <text textAnchor="end" x={this.props.doorWidth-20} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                        
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER017;