import React, { Component } from 'react';
import "./DoorIndent.css";

class DoorIndent extends Component {

    render() {
        return (
                <polygon id="indent" className="door-indent" points={0.5+","+0.5+" "+0.5+","+(this.props.doorHeight)+" "+10+","+(this.props.doorHeight)+" "+10+","+10+" "+(this.props.doorWidth-10)+","+10+" "+(this.props.doorWidth-10)+","+(this.props.doorHeight)+" "+(this.props.doorWidth)+","+(this.props.doorHeight)+" "+(this.props.doorWidth)+","+0.5} />
        );
    }
}

export default DoorIndent;