import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

class CER014 extends Component {

    render() {

        const izrezi = [];

        for (var i = 1; i <= 5; i++ ) {
            this.props.inoxAplikacije ?
            izrezi.push(
                <rect
                    key={uuidv4()}
                    className="izrez-inox"
                    x={250+5}
                    y={i*(this.props.doorHeight/6)}
                    width={this.props.doorWidth-500-5}
                    height={20}
                />
            ) :
            izrezi.push(
                <React.Fragment key={uuidv4()}>  
                    <rect
                        x={250+30}
                        y={i*(this.props.doorHeight/6)}
                        width={this.props.doorWidth-500-30}
                        height={7}
                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                    />
                    <rect
                        x={250+30}
                        y={i*(this.props.doorHeight/6)+15}
                        width={this.props.doorWidth-500-30}
                        height={7}
                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                    />
                </React.Fragment>
            )

        };

        return (
            <React.Fragment>
                
                { this.props.view === "outside" && 
                    <>
                        { this.props.inoxAplikacije ?
                            <React.Fragment>
                                <rect
                                    className="izrez-inox"
                                    x={250}
                                    y={10}
                                    width={20}
                                    height={this.props.doorHeight-10}
                                />
                                <rect
                                    className="izrez-inox"
                                    x={this.props.doorWidth-250}
                                    y={10}
                                    width={20}
                                    height={this.props.doorHeight-10}
                                />
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <rect
                                    x={250}
                                    y={10}
                                    width={7}
                                    height={this.props.doorHeight-10}
                                    style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                />
                                <rect
                                    x={this.props.doorWidth-250+15}
                                    y={10}
                                    width={7}
                                    height={this.props.doorHeight-10}
                                    style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                />
                                <rect
                                    x={this.props.doorWidth-250}
                                    y={10}
                                    width={7}
                                    height={this.props.doorHeight-10}
                                    style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                />
                                <rect
                                    x={250+15}
                                    y={10}
                                    width={7}
                                    height={this.props.doorHeight-10}
                                    style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                />
                            </React.Fragment>
                        }
                        <g style={{isolation: "isolate"}}>
                            {izrezi}
                        </g>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={250} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                        
                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER014;