import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER022 extends Component {

    render() {

        const stekla = [];
        const steklaIzrezi = [];
        const izrezi = [];

        for (var e = 1; e <= 4; e++ ) {
            stekla.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)}
                        y={-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300}
                        width={400}
                        height={200}
                        rx="10"
                    />
                </React.Fragment>
            )
            steklaIzrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={5+this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)}
                        y={-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90}
                        width={400-5}
                        height={20}
                    />
                </React.Fragment>
            )
            this.props.inoxAplikacije ?
            (izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        className="izrez-inox"
                        x={this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400}
                        y={-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90}
                        width={this.props.doorWidth-(this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400+5)}
                        height={20}
                    />
                </React.Fragment>)
            ):
            (izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400}
                        y={-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90}
                        width={this.props.doorWidth-(this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400+5)}
                        height={7}
                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                    />
                    <rect
                        x={this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400}
                        y={-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90+16}
                        width={this.props.doorWidth-(this.props.doorWidth/2-200 + Number((e % 2) ? 50 : -50)+400+5)}
                        height={7}
                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                    />
                </React.Fragment>
            ))
        }

        return (
            <React.Fragment>
                <clipPath id="stekloGlavno">
                    {stekla}
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {steklaIzrezi}
                </clipPath>

                {this.props.glassModel === "22-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "22-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "22-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "22-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "22-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "22-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                { 
                    this.props.view === "outside" &&
                    <React.Fragment>
                        {izrezi}
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER022;