import React, { Component } from 'react';

class CER013 extends Component {

    render() {

        return (
            <React.Fragment>    
                { this.props.view === "outside" && 
                    <>
                        <g style={{isolation: "isolate"}}>
                            { this.props.inoxAplikacije ?
                                <React.Fragment>
                                    <rect
                                        className="izrez-inox"
                                        x={this.props.doorWidth-250}
                                        y={5}
                                        width={45}
                                        height={this.props.doorHeight-5}
                                    />
                                    <rect
                                        className="izrez-inox"
                                        x={this.props.doorWidth-(250+2*50)}
                                        y={5}
                                        width={45}
                                        height={this.props.doorHeight-5}
                                    />
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <rect
                                        x={this.props.doorWidth-250}
                                        y={5}
                                        width={7}
                                        height={this.props.doorHeight-5}
                                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                    />
                                    <rect
                                        x={this.props.doorWidth-250+45}
                                        y={5}
                                        width={7}
                                        height={this.props.doorHeight-5}
                                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                    />

                                    <rect
                                        x={this.props.doorWidth-(250+2*50)}
                                        y={5}
                                        width={7}
                                        height={this.props.doorHeight-5}
                                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                                    />
                                    <rect
                                        x={this.props.doorWidth-(250+2*50)+45}
                                        y={5}
                                        width={7}
                                        height={this.props.doorHeight-5}
                                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                                    />
                                </React.Fragment>
                            }
                        </g>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "right" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="end" x={this.props.doorWidth-90} y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        :
                            <text x="90" y="250" fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                        
                    </>
                }
            </React.Fragment>
        );
    }
}

export default CER013;