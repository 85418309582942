import React, { Component } from 'react';
import { colorButtons } from "../../Constants";
import FrameGlassRight from "./frames/FrameGlassRight";
import FrameGlassLeft from "./frames/FrameGlassLeft";
import FrameGlassTop from "./frames/FrameGlassTop";


class Frame extends Component {

    render() {

        const doorColorFill = colorButtons.find(item => item.value === this.props.color);
        const doorColorInsideFill = colorButtons.find(item => item.value === this.props.colorInside);

        return (
            <svg x="0" y="0" id="frame">
                <g fill={this.props.view === "outside" ? (this.props.decorSet ? `url(#${this.props.colorDecor1})` : (this.props.extraMetalicSet ? `url(#${this.props.color})` : doorColorFill.style)) : (this.props.decorInsideSet ? `url(#${this.props.colorDecorInside})` : (this.props.extraMetalicSetNotranja ? `url(#${this.props.colorInside})` : doorColorInsideFill.style))}>
                    <g id="okvir">
                        <rect
                            x={0}
                            y={0}
                            width={52}
                            height={this.props.frameHeight}
                        />
                        <rect
                            x={0+this.props.frameWidth-50}
                            y={0}
                            width={52}
                            height={this.props.frameHeight}
                        />
                        <rect
                            x={0}
                            y={0}
                            width={this.props.frameWidth}
                            height={52}
                        />
                        
                        { this.props.glassWidthRight > 0 &&
                            <>
                                <rect
                                    x={this.props.frameWidth-100-this.props.glassWidthRight}
                                    y={this.props.frameHeight-this.props.doorHeight}
                                    width={50}
                                    height={this.props.doorHeight}
                                />
                                <rect
                                    x={this.props.frameWidth-50-this.props.glassWidthRight}
                                    y={this.props.frameHeight-50}
                                    width={this.props.glassWidthRight}
                                    height={50}
                                />
                            </>
                        }
                        { this.props.glassWidthLeft > 0 &&
                            <>
                                <rect
                                    x={50+this.props.glassWidthLeft}
                                    y={this.props.frameHeight-this.props.doorHeight}
                                    width={50}
                                    height={this.props.doorHeight}
                                />
                                <rect
                                    x={50}
                                    y={this.props.frameHeight-50}
                                    width={this.props.glassWidthLeft}
                                    height={50}
                                />
                            </>
                        }
                        { this.props.glassHeightTop > 0 &&
                            <>
                                <rect
                                    x={50}
                                    y={50+this.props.glassHeightTop}
                                    width={this.props.frameWidth-100}
                                    height={50}
                                />
                            </>
                        }
                    </g>
                </g>
                { this.props.glassWidthRight > 0 &&
                    <>
                        <FrameGlassRight
                            x={this.props.frameWidth-100-this.props.glassWidthRight+50}
                            y={this.props.frameHeight-this.props.doorHeight}
                            frameModel={this.props.frameModel}
                            doorModel={this.props.doorModel}
                            view={this.props.view}
                            handleDirection={this.props.handleDirection}
                            glassDimensions={this.props.glassWidthRight}
                            doorHeight={this.props.doorHeight-50}
                            doorWidth={this.props.doorWidth}
                            frameHeight={this.props.doorHeight}
                            frameWidth={this.props.frameWidth}
                            glassFrameModel={this.props.glassFrameModel}
                            inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                        />
                    </>
                }
                { this.props.glassWidthLeft > 0 &&
                    <>
                        <FrameGlassLeft
                            x={50}
                            y={this.props.frameHeight-this.props.doorHeight}
                            frameModel={this.props.frameModel}
                            doorModel={this.props.doorModel}
                            view={this.props.view}
                            handleDirection={this.props.handleDirection}
                            glassDimensions={this.props.glassWidthLeft}
                            doorHeight={this.props.doorHeight-50}
                            doorWidth={this.props.doorWidth}
                            frameHeight={this.props.doorHeight}
                            frameWidth={this.props.frameWidth}
                            glassFrameModel={this.props.glassFrameModel}
                            inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                        />
                    </>
                }
                { this.props.glassHeightTop > 0 &&
                    <>
                        <FrameGlassTop
                            x={50}
                            y={50}
                            glassDimensions={this.props.glassHeightTop}
                            frameHeight={this.props.doorHeight}
                            frameWidth={this.props.frameWidth-100}
                            view={this.props.view}
                        />
                    </>
                }
            </svg>
        );
        
    }
}


export default Frame;