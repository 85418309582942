import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";
import stekloRefleksivno from "../../glass/refleksivno-steklo.jpg";

class CER005 extends Component {

    render() {

        const stekla = []

        for (var i = 1; i <= 12; i++ ) {
            stekla.push(
                <rect
                    key={uuidv4()}
                    id="steklo-prosojno"
                    x={(this.props.doorWidth / 3)+53}
                    y={50+i*((this.props.doorHeight-((this.props.inoxOdbojnaLetev && this.props.view === "outside") ? 150 : 100))/13)}
                    width={(this.props.doorWidth / 3)-105}
                    height={20}
                    rx="10"
                />
            )
        }

        return (
            <React.Fragment>
                <clipPath id="fillGlassPath">
                    <rect
                        x={(this.props.doorWidth / 3)+50}
                        y={50}
                        width={(this.props.doorWidth / 3)-100}
                        height={this.props.doorHeight-((this.props.inoxOdbojnaLetev && this.props.view === "outside") ? 150 : 100)}
                        rx="10"
                    />
                </clipPath>
                <clipPath id="fillGlassPath2">
                    {stekla}
                </clipPath>
                <clipPath id="fill-refleksivno-steklo">
                    <rect
                        x={this.props.doorWidth / 3}
                        y={5}
                        width={this.props.doorWidth / 3}
                        height={this.props.doorHeight-5}
                    />
                </clipPath>
                { this.props.view === "outside" &&
                        <React.Fragment>
                            <image style={{WebkitClipPath: "url(#fill-refleksivno-steklo)", clipPath: "url(#fill-refleksivno-steklo)"}} height="100%" xlinkHref={stekloRefleksivno} />
                            {this.props.view === "outside" && 
                                this.props.handleDirection === "right" ? 
                                    <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={200} fontSize="130px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                        {this.props.hisnaStevilka}
                                    </text>
                                :
                                    <text textAnchor="end" x={this.props.doorWidth-20} y={200} fontSize="130px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                        {this.props.hisnaStevilka}
                                    </text>
                            }
                        </React.Fragment>
                }
                {this.props.glassModel === "05-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#fillGlassPath2)", clipPath: "url(#fillGlassPath2)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "05-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#fillGlassPath2)", clipPath: "url(#fillGlassPath2)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                {this.props.glassModel === "05-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "05-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                {this.props.glassModel === "05-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "05-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER005;