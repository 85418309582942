import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER016 extends Component {

    render() {

        const steklaIzrezi = []
        const izrezi = []
        const inox = []

        for (var e = 1; e <= 5; e++ ) {
            steklaIzrezi.push(
                <rect
                    key={uuidv4()}
                    x={(this.props.doorWidth-350)/2}
                    y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*233}
                    width={350}
                    height={20}
                    rx="10"
                />
            )
            izrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={10}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*233}
                        width={this.props.doorWidth-20}
                        height={7}
                        style={{fill: "rgba(0, 0, 0, 0.35)"}}
                    />
                    <rect
                        x={10}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+18+e*233}
                        width={this.props.doorWidth-20}
                        height={7}
                        style={{fill: "rgba(246, 246, 246, 0.35)"}}
                    />
                </React.Fragment>
            )
            inox.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        className="izrez-inox"
                        x={10}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*233}
                        width={this.props.doorWidth-20}
                        height={25}
                    />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                { this.props.view === "outside" &&
                    (this.props.inoxAplikacije ?
                        <React.Fragment>
                            {inox}
                        </React.Fragment>
                    :
                        <React.Fragment>
                            {izrezi}
                        </React.Fragment>
                    )
                }
                <clipPath id="stekloGlavno">
                    <rect
                        x={(this.props.doorWidth-350)/2}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)}
                        width={350}
                        height={1400}
                        rx="10"
                    />
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {steklaIzrezi}
                </clipPath>
                <clipPath id="stekloZgoraj">
                    <rect
                        x={(this.props.doorWidth-350)/2}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)}
                        width={350}
                        height={285}
                        rx="10"
                    />
                </clipPath>

                {this.props.glassModel === "16-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "16-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "16-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloZgoraj)", clipPath: "url(#stekloZgoraj)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "16-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloZgoraj)", clipPath: "url(#stekloZgoraj)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "16-3" && this.props.view === "outside" &&
                    <React.Fragment>
                         <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "16-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "16-4" && this.props.view === "outside" &&
                    <React.Fragment>
                         <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "16-4" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }

                {this.props.view === "outside" &&
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={210} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text textAnchor="middle" x={this.props.doorWidth/2} y={210} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    )
                }
            </React.Fragment>
        );
    }
}

export default CER016;