import React, { Component } from 'react';
import kljukaAlu from '../assets/doorHandles/kljuka-alu.png';
import kljukaInox from '../assets/doorHandles/kljuka-inox.png';
import kljukaAteneQ04matt from '../assets/doorHandles/kljuka-atene-q04-crna-matt.png';
import kljukaAteneQ04inox from '../assets/doorHandles/kljuka-atene-q04-inox.png';
import kljukaGeneveQ03matt from '../assets/doorHandles/kljuka-geneve-q03-crna-matt.png';
import kljukaGeneveQ03inox from '../assets/doorHandles/kljuka-geneve-q03-inox.png';
import kljukaOne10NMinimal from '../assets/doorHandles/kljuka-one-10n-minimal.png';
import kljukaToledoL14inox from '../assets/doorHandles/kljuka-toledo-l14-minimal-inox.png';
import kljukaToledoL14matt from '../assets/doorHandles/kljuka-toledo-l14-minimal-crna-matt.png';
import kljukaRhodosQ51 from '../assets/doorHandles/kljuka-rhodos-q51-slim-q01.png';
import citalec from "../assets/doorHandles/citalec-prstnih-odtisov.png"
import { handleModels } from "../../../Constants";


class Handle extends Component {

    render() {

        const handleModel = handleModels.find(item => item.handleName === this.props.handleModel);

        return (
            <React.Fragment>
                <defs>
                    <linearGradient
                        id="inox-rocaj"
                        x1="40.45"
                        y1={-4.64+this.props.doorHeight-(handleModel.length/2)-1050}
                        x2="85.21"
                        y2={(8.17+handleModel.length)-4.64+this.props.doorHeight-(handleModel.length/2)-1050}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0%" stopColor="#f1f1f1"/>
                        <stop offset="9%" stopColor="#d5d5d5"/>
                        <stop offset="18%" stopColor="#b5b5b5"/>
                        <stop offset="23%" stopColor="#c3c3c3"/>
                        <stop offset="33%" stopColor="#e7e7e7"/>
                        <stop offset="36%" stopColor="#f4f4f4"/>
                        <stop offset="38%" stopColor="#f4f4f4"/>
                        <stop offset="45%" stopColor="#f4f4f4"/>
                        <stop offset="53%" stopColor="#d1d1d1"/>
                        <stop offset="62%" stopColor="#b6b6b6"/>
                        <stop offset="69%" stopColor="#a6a6a6"/>
                        <stop offset="75%" stopColor="#a0a0a0"/>
                        <stop offset="87%" stopColor="#c9c7c7"/>
                        <stop offset="100%" stopColor="#999"/>
                    </linearGradient>
                    <filter id="drop-shadow" width="200%" height="200%">
                        <feDropShadow dx="10" dy="10" stdDeviation="3" floodOpacity=".5"/>
                    </filter>
                    <filter id="led-osvetlitev-utopni-rocaj" filterUnits="userSpaceOnUse"  x="-50%" y="-50%" width="200%" height="200%">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur5"/>
                        <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur10"/>
                        <feGaussianBlur in="SourceGraphic" stdDeviation="20" result="blur20"/>
                        <feGaussianBlur in="SourceGraphic" stdDeviation="30" result="blur30"/>
                        <feGaussianBlur in="SourceGraphic" stdDeviation="50" result="blur50"/>
                        <feMerge result="blur-merged">
                            <feMergeNode in="blur10"/>
                            <feMergeNode in="blur20"/>
                            <feMergeNode in="blur30"/>
                            <feMergeNode in="blur50"/>
                        </feMerge>
                        <feColorMatrix result="yellow-blur" in="blur-merged" type="matrix"
                                        values="1 0 0 0 0
                                        -0.2 1.0 0.3 0.1 0
                                        -0.1 0 1 0 0
                                        0 0 0 1 0 "/>
                        <feMerge>
                            <feMergeNode in="yellow-blur"/>
                            <feMergeNode in="blur5"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                        </filter>
                </defs>
                {
                    (this.props.handleModel === "rocaj-inox-600" ||
                    this.props.handleModel === "rocaj-inox-800" ||
                    this.props.handleModel === "rocaj-inox-1000" ||
                    this.props.handleModel === "rocaj-inox-1500" ||
                    this.props.handleModel === "rocaj-inox-1800") &&
                
                        <g style={{isolation: "isolate"}} filter="url(#drop-shadow)">
                            <g style={{stroke: "rgba(0,0,0,.25)", strokeWidth: "1"}}>
                                <rect
                                    x={this.props.defaultHandleX-20.5}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="39"
                                    height={handleModel.length}
                                    style={{fill: "url(#inox-rocaj)"}}
                                />
                                { this.props.citalecPrstnihOdtisov === "na-rocaju" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX-20.5} y={this.props.doorHeight-1300} width="39" />}
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect
                                        x={this.props.defaultHandleX+16.84}
                                        y={this.props.doorHeight-(handleModel.length/2)-1050}
                                        width="2.66"
                                        height={handleModel.length}
                                        style={{fill: "#d6d6d6"}}
                                    />
                                </g>
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect
                                        x={this.props.defaultHandleX-20.5}
                                        y={this.props.doorHeight-(handleModel.length/2)-1050}
                                        width="2.66"
                                        height={handleModel.length}
                                        style={{fill: "#e8e8e8"}}
                                    />
                                </g>
                            </g>
                        </g>
                }
                {
                    (this.props.handleModel === "rocaj-inox-okrogli-600" ||
                    this.props.handleModel === "rocaj-inox-okrogli-800" ||
                    this.props.handleModel === "rocaj-inox-okrogli-1000" ||
                    this.props.handleModel === "rocaj-inox-okrogli-1500" ||
                    this.props.handleModel === "rocaj-inox-okrogli-1800" ) &&
                
                        <g style={{isolation: "isolate"}} filter="url(#drop-shadow)">
                            <g style={{stroke: "rgba(0,0,0,.25)", strokeWidth: "1"}}>
                                <rect
                                    x={this.props.defaultHandleX-20.5}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="39"
                                    height={handleModel.length}
                                    rx="8"
                                    style={{fill: "url(#inox-rocaj)"}}
                                />
                                { this.props.citalecPrstnihOdtisov === "na-rocaju" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX-20.5} y={this.props.doorHeight-1300} width="39" />}
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect
                                        x={this.props.defaultHandleX+16.84}
                                        y={10+this.props.doorHeight-(handleModel.length/2)-1050}
                                        width="2.66"
                                        height={handleModel.length-20}
                                        style={{fill: "#d6d6d6"}}
                                    />
                                </g>
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect
                                        x={this.props.defaultHandleX-20.5}
                                        y={10+this.props.doorHeight-(handleModel.length/2)-1050}
                                        width="2.66"
                                        height={handleModel.length-20}
                                        style={{fill: "#e8e8e8"}}
                                    />
                                </g>
                            </g>
                        </g>
                }
                { this.props.handleModel === "kljuka-alu" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaAlu} x="30" y={this.props.doorHeight-1105} height="110" />
                    </> : 
                this.props.handleModel === "kljuka-inox" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaInox} x="30" y={this.props.doorHeight-1105} height="110" />
                    </> : 
                this.props.handleModel === "kljuka-atene-q04-crna-matt" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaAteneQ04matt} x="30" y={this.props.doorHeight-1075} height="50" />
                    </> : 
                this.props.handleModel === "kljuka-atene-q04-inox" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaAteneQ04inox} x="30" y={this.props.doorHeight-1075} height="50" />
                    </> : 
                this.props.handleModel === "kljuka-geneve-q03-crna-matt" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaGeneveQ03matt} x="30" y={this.props.doorHeight-1105} height="90" />
                    </> : 
                this.props.handleModel === "kljuka-geneve-q03-inox" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaGeneveQ03inox} x="30" y={this.props.doorHeight-1105} height="90" />
                    </> : 
                this.props.handleModel === "kljuka-one-10n-minimal" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaOne10NMinimal} x="30" y={this.props.doorHeight-1055} height="40" />
                    </> : 
                this.props.handleModel === "kljuka-toledo-l14-minimal-inox" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaToledoL14inox} x="30" y={this.props.doorHeight-1075} height="60" />
                    </> : 
                this.props.handleModel === "kljuka-toledo-l14-minimal-crna-matt" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaToledoL14matt} x="30" y={this.props.doorHeight-1075} height="60" />
                    </> : 
                this.props.handleModel === "kljuka-rhodos-q51-slim-q01" ?
                    <>
                        <image filter="url(#drop-shadow)" xlinkHref={kljukaRhodosQ51} x="30" y={this.props.doorHeight-1075} height="70" />
                    </> : 
                (this.props.handleModel === "rocaj-utopni-1400" ||
                this.props.handleModel === "rocaj-utopni-1600" ||
                this.props.handleModel === "rocaj-utopni-1800" ||
                this.props.handleModel === "rocaj-utopni-2000") ?
                    <g>
                        <rect
                            x={this.props.defaultHandleX-20.5}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={55}
                            height={handleModel.length}
                            style={{fill: "rgba(238,238,238,.15)", strokeWidth: "4", stroke: "rgba(68,72,72,.5)"}}
                        />
                        <rect
                            x={this.props.defaultHandleX-20.5}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={35}
                            height={handleModel.length-10}
                            style={{fill: "rgba(68,72,72)"}}
                        />
                        { this.props.citalecPrstnihOdtisov === "poleg-rocaja" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX-85} y={this.props.doorHeight-1300} width="35" />}
                    </g> :
                (this.props.handleModel === "rocaj-utopni-led-1400" ||
                this.props.handleModel === "rocaj-utopni-led-1600" ||
                this.props.handleModel === "rocaj-utopni-led-1800" ||
                this.props.handleModel === "rocaj-utopni-led-2000") ?
                    <g>
                        <rect
                            x={this.props.defaultHandleX-20.5}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={55}
                            height={handleModel.length}
                            style={{fill: "rgba(238,238,238,.15)", strokeWidth: "4", stroke: "rgba(68,72,72,.5)"}}
                        />
                        <rect
                            x={this.props.defaultHandleX-20.5}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={35}
                            height={handleModel.length-10}
                            style={{fill: "rgba(68,72,72)"}}
                        />
                        {/* <line
                            filter="url(#led-osvetlitev-utopni-rocaj)"
                            x1={this.props.defaultHandleX+15}
                            x2={this.props.defaultHandleX+15}
                            y1={100}
                            y2={500}
                            stroke="rgba(255,255,0,1)"
                            strokeWidth={100}
                        /> */}
                        <rect
                            filter="url(#led-osvetlitev-utopni-rocaj)"
                            x={this.props.defaultHandleX+20}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={3}
                            height={handleModel.length}
                            style={{ fill: "rgba(255,255,0,.5)"}}
                        />
                        <rect
                            x={this.props.defaultHandleX+20}
                            y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                            width={10}
                            height={handleModel.length}
                            style={{fill: "rgba(68,72,72)"}}
                        />
                        
                        { this.props.citalecPrstnihOdtisov === "poleg-rocaja" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX-85} y={this.props.doorHeight-1300} width="35" />}
                    </g> :
                (this.props.handleModel === "rocaj-inox-vgradni-1400" ||
                this.props.handleModel === "rocaj-inox-vgradni-1600" ||
                this.props.handleModel === "rocaj-inox-vgradni-1800" ||
                this.props.handleModel === "rocaj-inox-vgradni-2000") ?
                    <g style={{isolation: "isolate"}} filter="url(#drop-shadow)">
                        <rect
                            x={this.props.defaultHandleX}
                            y={this.props.doorHeight-(handleModel.length/2)-1050+100}
                            width={100}
                            height={handleModel.length-200}
                            style={{stroke: "rgba(238, 238, 238, .5)", strokeWidth: 5, fill: "rgba(0,0,0,1)"}}
                            rx="10"
                        />
                        <g style={{stroke: "rgba(0,0,0,.25)", strokeWidth: "1"}}>
                            <rect
                                x={this.props.defaultHandleX-20.5+50}
                                y={this.props.doorHeight-(handleModel.length/2)-1050}
                                width="39"
                                height={handleModel.length}
                                style={{fill: "url(#inox-rocaj)"}}
                            />
                            { this.props.citalecPrstnihOdtisov === "na-rocaju" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX+16.84+12} y={this.props.doorHeight-1300} width="39" />}
                            <g style={{mixBlendMode: "multiply"}}>
                                <rect
                                    x={this.props.defaultHandleX+16.84+50}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="2.66"
                                    height={handleModel.length}
                                    style={{fill: "#d6d6d6"}}
                                />
                            </g>
                            <g style={{mixBlendMode: "multiply"}}>
                                <rect
                                    x={this.props.defaultHandleX-20.5+50}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="2.66"
                                    height={handleModel.length}
                                    style={{fill: "#e8e8e8"}}
                                />
                            </g>
                        </g>
                    </g> :
                (this.props.handleModel === "rocaj-inox-vgradni-led-1400" ||
                this.props.handleModel === "rocaj-inox-vgradni-led-1600" ||
                this.props.handleModel === "rocaj-inox-vgradni-led-1800" ||
                this.props.handleModel === "rocaj-inox-vgradni-led-2000") ?
                    <g style={{isolation: "isolate"}} filter="url(#drop-shadow)">
                        <rect
                            x={this.props.defaultHandleX}
                            y={this.props.doorHeight-(handleModel.length/2)-1050+100}
                            width={100}
                            height={handleModel.length-200}
                            style={{stroke: "rgba(238, 238, 238, .5)", strokeWidth: 5, fill: "rgba(0,0,0,1)"}}
                            rx="10"
                        />
                        <g style={{stroke: "rgba(0,0,0,.25)", strokeWidth: "1"}}>
                            <rect
                                filter="url(#led-osvetlitev-utopni-rocaj)"
                                x={this.props.defaultHandleX+50}
                                y={(this.props.doorHeight >= 200+handleModel.length) ? (this.props.doorHeight-handleModel.length)/2 : (this.props.doorHeight-handleModel.length)/2}
                                width={5}
                                height={handleModel.length}
                                style={{ fill: "rgba(255,255,0,1)"}}
                            />
                            <rect
                                x={this.props.defaultHandleX-20.5+50}
                                y={this.props.doorHeight-(handleModel.length/2)-1050}
                                width="39"
                                height={handleModel.length}
                                style={{fill: "url(#inox-rocaj)"}}
                            />
                            { this.props.citalecPrstnihOdtisov === "na-rocaju" && this.props.view === "outside" && <image xlinkHref={citalec} x={this.props.defaultHandleX+16.84+12} y={this.props.doorHeight-1300} width="39" />}
                            <g style={{mixBlendMode: "multiply"}}>
                                <rect
                                    x={this.props.defaultHandleX+16.84+50}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="2.66"
                                    height={handleModel.length}
                                    style={{fill: "#d6d6d6"}}
                                />
                            </g>
                            <g style={{mixBlendMode: "multiply"}}>
                                <rect
                                    x={this.props.defaultHandleX-20.5+50}
                                    y={this.props.doorHeight-(handleModel.length/2)-1050}
                                    width="2.66"
                                    height={handleModel.length}
                                    style={{fill: "#e8e8e8"}}
                                />
                            </g>
                        </g>
                    </g>
                : this.props.handleModel === "rocaj-ukrivljeni-inox-1000" ?
                    <g
                        transform={`translate(100,${this.props.doorHeight/2-933/2}), rotate(-.5)`}
                        strokeMiterlimit="10"
                        filter="url(#drop-shadow)"
                    >
                        <path
                            d="M89.5 930.5c-132.75-422 0-928 0-928h-25s-139.5 506 0 928Z"
                            style={{fill: "url(#inox-rocaj)", stroke: "rgba(0,0,0,.5)", strokeWidth: 5}}
                        />
                        <path
                            d="M75.5 3.5s-132.75 504.36 0 925"
                            style={{fill: "none", stroke: "rgba(68,72,72,.5)", strokeWidth: 5}}
                        />
                        { this.props.citalecPrstnihOdtisov === "poleg-rocaja" && this.props.view === "outside" && <image xlinkHref={citalec} x={-60} y={this.props.doorHeight/2-840} width="25" />}
                    </g>
                : null }
            </React.Fragment>
        );
    }
}


export default Handle;