import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER021 extends Component {

    render() {

        const stekla = [];
        const steklaIzrezi = [];

        for (var e = 0; e <= 3; e++ ) {
            stekla.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.doorWidth-600}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2)+e*300}
                        width={500}
                        height={200}
                        rx="5"
                    />
                </React.Fragment>
            )
            steklaIzrezi.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.doorWidth-600+20}
                        y={(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2)+e*300+20}
                        width={460}
                        height={160}
                        rx="5"
                    />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <clipPath id="stekloGlavno">
                    {stekla}
                </clipPath>
                <clipPath id="stekloIzrezi">
                    {steklaIzrezi}
                </clipPath>

                {this.props.glassModel === "21-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "21-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#stekloIzrezi)", clipPath: "url(#stekloIzrezi)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }

                {this.props.glassModel === "21-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "21-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                
                {this.props.glassModel === "21-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "21-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#stekloGlavno)", clipPath: "url(#stekloGlavno)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                
                { 
                    this.props.view === "outside" &&
                    <React.Fragment>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="middle" x={this.props.doorWidth/2} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER021;