import React, { useState } from "react";
import { Modal, Button, Container, Row, Col, Table, Form } from "react-bootstrap";
import { Loader, Notification, toaster } from "rsuite";
import { doorModels, frameModels, colorButtons, doorDecor, handleDirection, inoxOdbojnaLetev, inoxAplikacije, skritiPanti, citalecPrstnihOdtisov, handleModels, doorSeries, doorLock } from "../Constants";
import { jsPDF } from "jspdf";
import { svgAsPngUri } from "save-svg-as-png";
import { Send } from "react-bootstrap-icons";
import { RobotoRegularTTF, RobotoMediumTTF } from "../assets/Roboto";
import axios from "axios";
import WaterMark from "../assets/watermark2XCeral.png";
import CeralLogo from "../assets/images/logo.png";
import "./submit.css";

function Submit(props) {
    const [show, setShow] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [slikaVrat, setSlikaVrat] = useState(null);
	const [validated, setValidated] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [ime, setIme] = useState("");
	const [telefon, setTelefon] = useState("");
	const [naslov, setNaslov] = useState("");
	const [sporocilo, setSporocilo] = useState("");

	// const resetForm = () => {
	// 	setEmail();
	// 	setIme();
	// 	setTelefon();
	// 	setNaslov();
	// 	setSporocilo();
	// };
  
    const handleClose = () => {setShow(false);};
    const handleShow = () => {
		setShow(true)
		setComponentLoading(true)
	};

	const getPNG = () => {
		var svg = document.getElementById("SVGvrataInOut")
		svgAsPngUri(svg,{scale: 0.75}).then(uri => {
			setSlikaVrat(uri)
		}).then(()=>{setComponentLoading(false)});
	}

	async function downLoadPDF() {
		setLoading(true);
		const pdf = new jsPDF("p", "mm", "a4");

		pdf.setProperties({
			title: 'Konfiguracija vrat - Ceral.si',
			subject: 'Konfiguracija vrat',
			author: 'ČERAL ing., d. o. o. ',
			creator: 'Konfigurator vrat - dpoint.si'
		});

		pdf.addFileToVFS("Roboto-Regular.ttf", RobotoRegularTTF);
		pdf.addFont("Roboto-Regular.ttf", "Roboto-Regular", "normal");
		pdf.addFileToVFS("Roboto-Medium.ttf", RobotoMediumTTF);
		pdf.addFont("Roboto-Medium.ttf", "Roboto-Medium", "bold");

		pdf.setFont("Roboto-Regular");

		pdf.setFontSize(12).setFont("Roboto-Medium", 'bold');
		pdf.text(10, 13, 'Pogled od zunaj:')
		pdf.text(((props.configuration.frameWidth+100)/30+15), 13, 'Pogled od znotraj:')

		pdf.addImage(slikaVrat, "PNG", 10, 15, (props.configuration.frameWidth*2+200)/30, props.configuration.frameHeight/30, undefined, 'NONE');

		const odmikOdLeve = 10;
		const odmikOdTekstaLevo = 100;
		const crtaKoncnaDesno = 200;
		const pomikDol = props.configuration.frameHeight/30+30;
		var odmikLine = pomikDol+5;
		var odmikText = pomikDol+4+5;

		pdf.text(odmikOdLeve, pomikDol, 'Izbrana konfiguracija');

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Model:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, doorModel.doorName);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Tip okvirja:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, frameModel.frameName);
		odmikLine += 6;
		odmikText += 6;

		if (frameModel.value !== "01") {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Širina vrat:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${doorWidth} mm`);
			odmikLine += 6;
			odmikText += 6;

			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Višina vrat:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${doorHeight} mm`);
			odmikLine += 6;
			odmikText += 6;

			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Skupna širina:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${frameWidth} mm`);
			odmikLine += 6;
			odmikText += 6;

			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Skupna višina:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${frameHeight} mm`);
			odmikLine += 6;
			odmikText += 6;
		} else {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Skupna širina:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${doorWidth} mm`);
			odmikLine += 6;
			odmikText += 6;

			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Skupna višina:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${doorHeight} mm`);
			odmikLine += 6;
			odmikText += 6;
		}

		if (frameModel.glassLeft > 0) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Širina leve obsvetlobe:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${frameLeftGlassWidth} mm`);
			odmikLine += 6;
			odmikText += 6;
		}

		if (frameModel.glassRight > 0) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Širina desne obsvetlobe:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${frameRightGlassWidth} mm`);
			odmikLine += 6;
			odmikText += 6;
		}

		if (frameModel.glassTop > 0) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Višina nadsvetlobe:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${frameTopGlassHeight} mm`);
			odmikLine += 6;
			odmikText += 6;
		}

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Odpiranje:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${handleDir.name}`);
		odmikLine += 6;
		odmikText += 6;

		if (props.configuration.decorSet) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Zunanji dekor:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${decor1.decorName}`);
			odmikLine += 6;
			odmikText += 6;
		} else {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Zunanja barva:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${color1.colorName}`);
			odmikLine += 6;
			odmikText += 6;
		}

		if (props.configuration.decorInsideSet) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Notranji dekor:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${decorInside.decorName}`);
			odmikLine += 6;
			odmikText += 6;
		} else {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Notranja barva:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${colorInside.colorName}`);
			odmikLine += 6;
			odmikText += 6;
		}
		
		if (props.configuration.secondDecorSet) {
			if (props.configuration.colorDecor2) {
				pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
				pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
				pdf.setFontSize(10).setFont(undefined, 'bold');
				pdf.text(odmikOdLeve, odmikText, 'Dekor element:');
				pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
				pdf.setFontSize(10).setFont(undefined, 'normal');
				pdf.text(odmikOdTekstaLevo, odmikText, `${decor2.decorName}`);
				odmikLine += 6;
				odmikText += 6;
			}
		}
		if (doorModel.glass) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Motiv stekla vrata:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${(doorModel.glassModels.find(item => item.model === glassDoorModel)).description}`);
			odmikLine += 6;
			odmikText += 6;
		}
		
		if(frameModel.glass) {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Motiv stekla okvir:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${(doorModel.glassFrameModels.find(item => item.model === glassFrameModel)).description}`);
			odmikLine += 6;
			odmikText += 6;
		}

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'INOX letev:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${inoxOdbLetev.name}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'INOX aplikacije:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${inoxApl.name}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'INOX aplikacije:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${inoxApl.name}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Skriti panti:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${skritiPan.name}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Serija:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${doorSerie.name}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Ključavnica:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${doorLck.lockDescription}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Kljuka notri:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${handleModelInside.handleDescription}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Kljuka zunaj:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${handleModelOutside.handleDescription}`);
		odmikLine += 6;
		odmikText += 6;

		pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
		pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
		pdf.text(odmikOdLeve, odmikText, 'Čitalec prstnih odtisov:');
		pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
		pdf.text(odmikOdTekstaLevo, odmikText, `${citalecOdtisov.name}`);
		odmikLine += 6;
		odmikText += 6;

		if (hisnaStevilka !== "") {
			pdf.line(odmikOdLeve, odmikLine, crtaKoncnaDesno, odmikLine);
			pdf.setFontSize(10).setFont("Roboto-Medium", 'bold');
			pdf.text(odmikOdLeve, odmikText, 'Izbrana hišna številka:');
			pdf.setFontSize(10).setFont("Roboto-Regular", 'normal');
			pdf.text(odmikOdTekstaLevo, odmikText, `${hisnaStevilka}`);
			odmikLine += 6;
			odmikText += 6;
		}

		pdf.addImage(WaterMark, "PNG", 10, 20, 100, 58);

		pdf.save(`Ceral.si-konfiguriracija-vrat-${Math.floor(10000 + Math.random() * 9000)}.pdf`);
		setLoading(false);
	}

	let doorModel = doorModels.find(item => item.value === props.configuration.model);
	let frameModel = frameModels.find(item => item.value === props.configuration.frameModel);
	let color1 = colorButtons.find(item => item.value === props.configuration.color);
	let colorInside = colorButtons.find(item => item.value === props.configuration.colorInside);
	let decor1 = doorDecor.find(item => item.value === props.configuration.colorDecor1);
	let decor2 = doorDecor.find(item => item.value === props.configuration.colorDecor2);
	let decorInside = doorDecor.find(item => item.value === props.configuration.colorDecorInside);
	let frameWidth = props.configuration.dimFrameWidth;
	let frameHeight = props.configuration.dimFrameHeight;
	let doorWidth = props.configuration.doorWidth;
	let doorHeight = props.configuration.doorHeight;
	let handleDir = handleDirection.find(item => item.value === props.configuration.handleDirection);
	let glassDoorModel = props.configuration.glassModel;
	let glassFrameModel = props.configuration.glassFrameModel;
	let frameLeftGlassWidth = props.configuration.glassWidthLeft;
	let frameRightGlassWidth = props.configuration.glassWidthRight;
	let frameTopGlassHeight = props.configuration.glassHeightTop;
	let inoxOdbLetev = inoxOdbojnaLetev.find(item => item.value === props.configuration.inoxOdbojnaLetev);
	let inoxApl = inoxAplikacije.find(item => item.value === props.configuration.inoxAplikacije);
	let skritiPan = skritiPanti.find(item => item.value === props.configuration.skritiPanti);
	let citalecOdtisov = citalecPrstnihOdtisov.find(item => item.value === props.configuration.citalecPrstnihOdtisov);
	let handleModelOutside = handleModels.find(item => item.handleName === props.configuration.handleModelOutside);
	let handleModelInside = handleModels.find(item => item.handleName === props.configuration.handleModelInside);
	let doorSerie = doorSeries.find(item => item.value === props.configuration.doorSeries);
	let doorLck = doorLock.find(item => item.lockName === props.configuration.doorLock);
	let hisnaStevilka = props.configuration.hisnaStevilka;

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		
		if (form.checkValidity() === false) {
		  	event.preventDefault();
		  	event.stopPropagation();
		}

		setValidated(true);

		const messageHtml = `
			<table>
				<tbody>
					<tr>
						<td style="font-weight: 600">Model</td>
						<td>${doorModel.doorName}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Tip okvirja</td>
						<td>${frameModel.frameName}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Širina vrat</td>
						<td>${frameModel.value === "01" ? "Brez" : doorWidth} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Višina vrat</td>
						<td>${frameModel.value === "01" ? "Brez" : doorHeight} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Skupna širina</td>
						<td>${frameModel.value === "01" ? doorWidth : frameWidth} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Skupna višina</td>
						<td>${frameModel.value === "01" ? doorHeight : frameHeight} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Širina leve obsvetlobe</td>
						<td>${frameModel.glassLeft > 0 ? frameLeftGlassWidth : "///"} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Širina desne obsvetlobe</td>
						<td>${frameModel.glassRight > 0 ? frameRightGlassWidth : "///"} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Višina nadsvetlobe</td>
						<td>${frameModel.glassTop > 0 ? frameTopGlassHeight : "///"} mm</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Odpiranje</td>
						<td>${handleDir.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Zunanja barva oz. dekor</td>
						<td>${props.configuration.decorSet ? decor1.decorName : color1.colorName}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Notranja barva oz. dekor</td>
						<td>${props.configuration.decorInsideSet ? decorInside.decorName : colorInside.colorName}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Drugi dekor element</td>
						<td>${props.configuration.secondDecorSet ? (props.configuration.colorDecor2 ? decor2.decorName : "Brez / ni nastavljen") : "Drugi dekor element ni izbran"}</td>
					</tr> 
					<tr>
						<td style="font-weight: 600">Motiv stekla vrata</td>
						<td>${doorModel.glass ? (doorModel.glassModels.find(item => item.model === glassDoorModel)).description : "Brez stekla"}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Motiv stekla okvir</td>
						<td>${frameModel.glass ? (doorModel.glassFrameModels.find(item => item.model === glassFrameModel)).description : "Brez stekla"}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">INOX letev</td>
						<td>${inoxOdbLetev.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">INOX aplikacije</td>
						<td>${inoxApl.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Skriti panti</td>
						<td>${skritiPan.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Serija</td>
						<td>${doorSerie.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Ključavnica</td>
						<td>${doorLck.lockDescription}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Kljuka notri</td>
						<td>${handleModelInside.handleDescription}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Kljuka zunaj</td>
						<td>${handleModelOutside.handleDescription}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Čitalec prstnih odtisov</td>
						<td>${citalecOdtisov.name}</td>
					</tr>
					<tr>
						<td style="font-weight: 600">Izbrana hišna številka</td>
						<td>${hisnaStevilka !== "" ? hisnaStevilka : "Brez"}</td>
					</tr>
				</tbody>
			</table>
		`;

		const messageSuccess = <Notification type="success" header="Sporočilo uspešno poslano!" closable></Notification>
		const messageError = <Notification type="error" header="Prišlo je do napake!" closable>Prosimo poskusite znova ali pa nam pišite na info@ceral.si</Notification>

		axios({
			method: "POST",
			// url: "https://konfigurator.ceral.si/express_backend/send" + package.json: "proxy": "https://konfigurator.ceral.si/express_backend",
			// LOCAL TESTING:(+ add "proxy": "http://localhost:5000/" to package.json) url: "http://localhost:3000/send",
			// url: "http://localhost:3000/send",
			// url: "https://konfigurator.ceral.si/express_backend/send",
			url: "https://konfigurator.ceral.si/express_backend/send",
			data: {
				email: email,
				ime: ime,
				telefon: telefon,
				naslov: naslov,
				sporocilo: sporocilo,
				messageHtml: messageHtml,
				slikaVrat: slikaVrat
			}
		}).then((response) => {
			if (response.data.msg === "success") {
				// console.log(messageHtml)
				toaster.push(messageSuccess, { placement: "topStart" })
				// resetForm()
			} else if (response.data.msg === "fail") {
				// alert("Oops, something went wrong. Try again")
				toaster.push(messageError, { placement: "topStart" })
			} else {
				toaster.push(messageError, { placement: "topStart" })
			}
		})
	};
  
    return (
      <>
        <Button variant="info" onClick={() => {handleShow();getPNG();}} size="lg" style={{}}>
			<Send size={25} />Pošlji povpraševanje
        </Button>
  
        <Modal
          	show={show}
          	onHide={handleClose}
          	backdrop="static"
          	keyboard={true}
          	aria-labelledby="contained-modal-title-vcenter"
			dialogClassName="modal-90w"
          	centered
        >
			<Modal.Header closeButton>
				<Modal.Title>Pošlji povpraševanje</Modal.Title>
			</Modal.Header>
			<Modal.Body className="show-grid">
				<Container>
					<Row id="print2PDF">
						<Col xs={12} md={6}>
							<h5>Izbran model (pogled od zunaj in pogled od znotraj):</h5>
							<img src={slikaVrat} alt="izbrana konfiguracija vrat" width={"100%"}/>
						</Col>
						<Col xs={12} md={6}>
							<h5>Karakteristike</h5>
							<Table striped bordered hover size="sm" id="table-konfiguracija">
								<tbody>
									<tr>
										<td className="bold">Model</td>
										<td>{doorModel.doorName}</td>
									</tr>
									<tr>
										<td className="bold">Tip okvirja</td>
										<td>{frameModel.frameName}</td>
									</tr>
									{frameModel.value === "01" ?
										<React.Fragment>
											<tr>
												<td className="bold">Skupna širina</td>
												<td>{doorWidth} mm</td>
											</tr>
											<tr>
												<td className="bold">Skupna višina</td>
												<td>{doorHeight} mm</td>
											</tr>
										</React.Fragment> :
										<React.Fragment>
											<tr>
												<td className="bold">Širina vrat</td>
												<td>{doorWidth} mm</td>
											</tr>
											<tr>
												<td className="bold">Višina vrat</td>
												<td>{doorHeight} mm</td>
											</tr>
											<tr>
												<td className="bold">Skupna širina</td>
												<td>{frameWidth} mm</td>
											</tr>
											<tr>
												<td className="bold">Skupna višina</td>
												<td>{frameHeight} mm</td>
											</tr>
										</React.Fragment>
									}
									{frameModel.glassLeft > 0 &&
										<tr>
											<td className="bold">Širina leve obsvetlobe</td>
											<td>{frameLeftGlassWidth} mm</td>
										</tr>}
									{frameModel.glassRight > 0 &&
										<tr>
											<td className="bold">Širina desne obsvetlobe</td>
											<td>{frameRightGlassWidth} mm</td>
										</tr>}
									{frameModel.glassTop > 0 &&
										<tr>
											<td className="bold">Višina nadsvetlobe</td>
											<td>{frameTopGlassHeight} mm</td>
										</tr>}
									<tr>
										<td className="bold">Odpiranje</td>
										<td>{handleDir.name}</td>
									</tr>
									{props.configuration.decorSet ?
									<tr>
										<td className="bold">Zunanji dekor</td>
										<td>{decor1.decorName}</td>
									</tr>
									:
									<tr>
										<td className="bold">Zunanja barva</td>
										<td>{color1.colorName}</td>
									</tr>}
									{props.configuration.decorInsideSet ?
									<tr>
										<td className="bold">Notranji dekor</td>
										<td>{decorInside.decorName}</td>
									</tr>
									:
									<tr>
										<td className="bold">Notranja barva</td>
										<td>{colorInside.colorName}</td>
									</tr>}
									{props.configuration.secondDecorSet &&
										(props.configuration.colorDecor2 &&
											<tr>
												<td className="bold">Dekor element</td>
												<td>{decor2.decorName}</td>
											</tr>)
									}
									{doorModel.glass &&
										<tr>
											<td className="bold">Motiv stekla vrata</td>
											<td>{(doorModel.glassModels.find(item => item.model === glassDoorModel)).description}</td>
										</tr>
									}
									{frameModel.glass &&
										<tr>
											<td className="bold">Motiv stekla okvir</td>
											<td>{(doorModel.glassFrameModels.find(item => item.model === glassFrameModel)).description}</td>
										</tr>
									}
									<tr>
										<td className="bold">INOX letev</td>
										<td>{inoxOdbLetev.name}</td>
									</tr>
									<tr>
										<td className="bold">INOX aplikacije</td>
										<td>{inoxApl.name}</td>
									</tr>
									<tr>
										<td className="bold">Skriti panti</td>
										<td>{skritiPan.name}</td>
									</tr>
									<tr>
										<td className="bold">Serija</td>
										<td>{doorSerie.name}</td>
									</tr>
									<tr>
										<td className="bold">Ključavnica</td>
										<td>{doorLck.lockDescription}</td>
									</tr>
									<tr>
										<td className="bold">Kljuka notri</td>
										<td>{handleModelInside.handleDescription}</td>
									</tr>
									<tr>
										<td className="bold">Kljuka zunaj</td>
										<td>{handleModelOutside.handleDescription}</td>
									</tr>
									<tr>
										<td className="bold">Čitalec prstnih odtisov</td>
										<td>{citalecOdtisov.name}</td>
									</tr>
									{hisnaStevilka !== "" &&
										<tr>
											<td className="bold">Izbrana hišna številka</td>
											<td>{hisnaStevilka}</td>
										</tr>
									}
								</tbody>
							</Table>
						</Col>
					</Row>
					<Row>
						<Col>
							<Form validated={validated} onSubmit={handleSubmit}>
								<h3>Oddaja povpraševanja</h3>
								<Form.Row>
									<Form.Group as={Col} >
										<Form.Label>Elektronski naslov</Form.Label>
										<Form.Control onChange={e => setEmail(e.target.value)} value={email} id="email" required type="email" placeholder="Vpišite elektronski naslov" />
										<Form.Control.Feedback type="invalid">Vpišite veljaven elektronski naslov</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} >
										<Form.Label>Ime in priimek</Form.Label>
										<Form.Control onChange={e => setIme(e.target.value)} value={ime} id="ime" required type="text" placeholder="Vpišite ime in priimek" />
										<Form.Control.Feedback type="invalid">Vpišite ime in priimek</Form.Control.Feedback>
									</Form.Group>
								</Form.Row>

								<Form.Row>
									<Form.Group as={Col} >
										<Form.Label>Telefonska številka</Form.Label>
										<Form.Control onChange={e => setTelefon(e.target.value)} value={telefon} id="telefon" required type="text" placeholder="Vpišite telefonsko številko" />
										<Form.Control.Feedback type="invalid">Vpišite telefonsko številko</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} >
										<Form.Label>Lokacija objekta</Form.Label>
										<Form.Control onChange={e => setNaslov(e.target.value)} value={naslov} id="naslov" required type="text" placeholder="Vpišite okvirno lokacijo objekta" />
										<Form.Control.Feedback type="invalid">Vpišite okvirno lokacijo objekta</Form.Control.Feedback>
									</Form.Group>
								</Form.Row>

								<Form.Group>
									<Form.Label>Sporočilo</Form.Label>
									<Form.Control onChange={e => setSporocilo(e.target.value)} value={sporocilo} id="sporocilo" required as="textarea" rows={2} placeholder="Napišite sporočilo" />
									<Form.Control.Feedback type="invalid">Napišite sporočilo</Form.Control.Feedback>
								</Form.Group>

								<Form.Group id="formGDPR">
									<Form.Check type="checkbox" label="Strinjam se z obdelavo osebnih podatkov za namen izdelave in pošiljanje ponudbe." required/>
								</Form.Group>

								<Button variant="primary" type="submit">
									Pošlji povpraševanje
								</Button>
							</Form>
						</Col>
						<Col>
							<div className="povprasevanje-ceral-info">
								<Row>
									<Col>
										<h3 style={{textAlign: "center"}}><b>ČERAL ing., d. o. o.</b></h3>
									</Col>
								</Row>
								<Row>
									<Col sm={12} xs={12}>
										<h6>Ob potoku 52A,<br />1000 Ljubljana</h6>
										<h6><a href="mailto:info@ceral.si">info@ceral.si</a></h6>
										<h6><a href="tel:0038641691094">+386 41 691 094</a></h6>
										<h6><a href="tel:0038670871768">+386 70 871 768</a></h6>
									</Col>
									<Col sm={12} xs={12} style={{display: "flex", alignItems: "center"}}>
										<div>
											<img src={CeralLogo} alt="Čeral logo" style={{width: "100%"}} />
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Zapri
				</Button>
				<Button variant="info" disabled={isLoading} id="getPDF" onClick={downLoadPDF}>{isLoading ? 'Nalagam ...' : 'Shrani in prenesi PDF'}</Button>
			</Modal.Footer>
			
			{ componentLoading && <Loader backdrop size="lg" content="Nalagam ..." vertical />}
        </Modal>
      </>
    );
}

export default Submit