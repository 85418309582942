import React, { Component } from 'react';
import DoorIndent from './doors/DoorIndent';
import Handle from './doors/Handle';
import CER001 from './doors/models/CER001';
import CER002 from './doors/models/CER002';
import CER003 from './doors/models/CER003';
import CER004 from './doors/models/CER004';
import CER005 from './doors/models/CER005';
import CER006 from './doors/models/CER006';
import CER007 from './doors/models/CER007';
import CER008 from './doors/models/CER008';
import CER009 from './doors/models/CER009';
import CER010 from './doors/models/CER010';
import CER011 from './doors/models/CER011';
import CER012 from './doors/models/CER012';
import CER013 from './doors/models/CER013';
import CER014 from './doors/models/CER014';
import CER015 from './doors/models/CER015';
import CER016 from './doors/models/CER016';
import CER017 from './doors/models/CER017';
import CER018 from './doors/models/CER018';
import CER019 from './doors/models/CER019';
import CER020 from './doors/models/CER020';
import CER021 from './doors/models/CER021';
import CER022 from './doors/models/CER022';
import KeylockOut from '../../assets/images/keylockOut.png';
import KeylockIn from '../../assets/images/keylockIn.png';
import Hrast from "./assets/decor/hrast.jpg";
import Macesen from "./assets/decor/macesen.jpg";
import zlatiHrast from "./assets/decor/zlati-hrast.jpg";
import temniHrast from "./assets/decor/temni-hrast.jpg";
import Mehagonij from "./assets/decor/mehagonij.jpg";
import TR021 from "./assets/decor/TR-021.jpg";
import RAL9006FS from "./assets/decor/RAL-9006-FS.jpg";
import RAL9007FS from "./assets/decor/RAL-9007-FS.jpg";
import { colorButtons, doorModels } from "../../Constants";
import { v4 as uuidv4 } from 'uuid';

class Door extends Component {

    render() {
        var newyf = this.props.doorHeight/1800;
        var razlikaVisine = this.props.doorHeight - 1800;

        const doorColorFill = colorButtons.find(item => item.value === this.props.color);
        const doorColorInsideFill = colorButtons.find(item => item.value === this.props.colorInside);
        const doorModel = doorModels.find(item => item.value === this.props.model);

        return (
            <svg id="door" x={this.props.glassWidthLeft > 0 ? this.props.glassWidthLeft + 100 : 50} y={this.props.glassHeightTop > 0 ? this.props.glassHeightTop + 100 : 50} >
                <g transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(${(this.props.glassWidthLeft > 0 ? (this.props.glassWidthLeft + 125) : 125) - this.props.frameWidth - 50 + (this.props.glassWidthRight > 0 ? (this.props.glassWidthRight + 75) : 75) + (this.props.glassWidthLeft > 0 && this.props.glassWidthRight > 0 ? 50 : 0) + (this.props.glassWidthLeft === 0 && this.props.glassWidthRight === 0 && -50 )} 0)`} >
                    <defs>
                        {this.props.view === "outside" ?
                        <>
                            {((this.props.decorSet && this.props.colorDecor1 === "hrast") || (this.props.decor2Set && this.props.colorDecor2 === "hrast")) &&
                            <pattern id="hrast" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Hrast} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {((this.props.decorSet && this.props.colorDecor1 === "macesen") || (this.props.decor2Set && this.props.colorDecor2 === "macesen")) &&
                            <pattern id="macesen" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Macesen} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {((this.props.decorSet && this.props.colorDecor1 === "temni-hrast") || (this.props.decor2Set && this.props.colorDecor2 === "temni-hrast")) &&
                            <pattern id="temni-hrast" patternUnits="userSpaceOnUse" width="960" height="1500">
                                <image xlinkHref={temniHrast} x="0" y="0" width="960" height="1500"></image>
                            </pattern>}
                            {((this.props.decorSet && this.props.colorDecor1 === "zlati-hrast") || (this.props.decor2Set && this.props.colorDecor2 === "zlati-hrast")) &&
                            <pattern id="zlati-hrast" patternUnits="userSpaceOnUse" width="758" height="1400">
                                <image xlinkHref={zlatiHrast} x="0" y="0" width="758" height="1400"></image>
                            </pattern>}
                            {((this.props.decorSet && this.props.colorDecor1 === "mehagonij") || (this.props.decor2Set && this.props.colorDecor2 === "mehagonij")) &&
                            <pattern id="mehagonij" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Mehagonij} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSet && this.props.color === "TR-021" &&
                            <pattern id="TR-021" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={TR021} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSet && this.props.color === "RAL-9006-FS" &&
                            <pattern id="RAL-9006-FS" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={RAL9006FS} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSet && this.props.color === "RAL-9007-FS" &&
                            <pattern id="RAL-9007-FS" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={RAL9007FS} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                        </>
                        :
                        <>
                            {((this.props.decorInsideSet && this.props.colorDecorInside === "hrast")) &&
                            <pattern id="hrast" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Hrast} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {((this.props.decorInsideSet && this.props.colorDecorInside === "macesen")) &&
                            <pattern id="macesen" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Macesen} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {((this.props.decorInsideSet && this.props.colorDecorInside === "temni-hrast")) &&
                            <pattern id="temni-hrast" patternUnits="userSpaceOnUse" width="960" height="1500">
                                <image xlinkHref={temniHrast} x="0" y="0" width="960" height="1500"></image>
                            </pattern>}
                            {((this.props.decorInsideSet && this.props.colorDecorInside === "zlati-hrast")) &&
                            <pattern id="zlati-hrast" patternUnits="userSpaceOnUse" width="758" height="1400">
                                <image xlinkHref={zlatiHrast} x="0" y="0" width="758" height="1400"></image>
                            </pattern>}
                            {((this.props.decorInsideSet && this.props.colorDecorInside === "mehagonij")) &&
                            <pattern id="mehagonij" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={Mehagonij} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSetNotranja && this.props.colorInside === "TR-021" &&
                            <pattern id="TR-021" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={TR021} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSetNotranja && this.props.colorInside === "RAL-9006-FS" &&
                            <pattern id="RAL-9006-FS" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={RAL9006FS} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                            {this.props.extraMetalicSetNotranja && this.props.colorInside === "RAL-9007-FS" &&
                            <pattern id="RAL-9007-FS" patternUnits="userSpaceOnUse" width="750" height="750">
                                <image xlinkHref={RAL9007FS} x="0" y="0" width="750" height="750"></image>
                            </pattern>}
                        </>}

                        <linearGradient
                            id="inox"
                            x1={291.27+5}
                            y1={-349.46-720}
                            x2={430.03+(this.props.doorWidth-720.63-5)}
                            y2={383.98-19}
                            gradientTransform="rotate(90)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0%" stopColor="#f1f1f1"/>
                            <stop offset="09%" stopColor="#d5d5d5"/>
                            <stop offset="18%" stopColor="#b5b5b5"/>
                            <stop offset="23%" stopColor="#c3c3c3"/>
                            <stop offset="33%" stopColor="#e7e7e7"/>
                            <stop offset="36%" stopColor="#f4f4f4"/>
                            <stop offset="38%" stopColor="#f4f4f4"/>
                            <stop offset="45%" stopColor="#f4f4f4"/>
                            <stop offset="53%" stopColor="#d1d1d1"/>
                            <stop offset="62%" stopColor="#b6b6b6"/>
                            <stop offset="69%" stopColor="#a6a6a6"/>
                            <stop offset="75%" stopColor="#a0a0a0"/>
                            <stop offset="87%" stopColor="#c9c7c7"/>
                            <stop offset="100%" stopColor="#999"/>
                        </linearGradient>
                    </defs>
                    {this.props.view === "outside" ?
                        <rect id="krilo" x="0" y="0" fill={this.props.decorSet ? `url(#${this.props.colorDecor1})` : (this.props.extraMetalicSet ? `url(#${this.props.color})` : doorColorFill.style)} width={this.props.doorWidth} height={this.props.doorHeight} opacity={"1"} /> :
                        <rect id="krilo"  x="0" y="0" fill={this.props.decorInsideSet ? `url(#${this.props.colorDecorInside})` : (this.props.extraMetalicSetNotranja ? `url(#${this.props.colorInside})` : doorColorInsideFill.style) } width={this.props.doorWidth} height={this.props.doorHeight} opacity={"1"} />
                    }
                    

                    <DoorIndent doorWidth={this.props.doorWidth} doorHeight={this.props.doorHeight}/>
                    
                    { doorModel.value  === "CER-001" ?
                            <CER001
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorHeight={this.props.doorHeight}
                                doorWidth={this.props.doorWidth}
                                inoxAplikacije={this.props.inoxAplikacije}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-002" ?
                            <CER002
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                newyf={newyf}
                                doorWidth={this.props.doorWidth}
                                razlikaVisine={razlikaVisine}
                                inoxAplikacije={this.props.inoxAplikacije}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-003" ?
                            <CER003
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorHeight={this.props.doorHeight}
                                doorWidth={this.props.doorWidth}
                                inoxAplikacije={this.props.inoxAplikacije}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-004" ?
                            <CER004
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorHeight={this.props.doorHeight}
                                doorWidth={this.props.doorWidth}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-005" ?
                            <CER005
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                                inoxAplikacije={this.props.inoxAplikacije}
                            />
                        : doorModel.value === "CER-006" ?
                            <CER006
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-007" ?
                            <CER007
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-008" ?
                            <CER008
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-009" ?
                            <CER009
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-010" ?
                            <CER010
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-011" ?
                            <CER011
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-012" ?
                            <CER012
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-013" ?
                            <CER013
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-014" ?
                            <CER014
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-015" ?
                            <CER015
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                                inoxAplikacije={this.props.inoxAplikacije}
                            />
                        : doorModel.value === "CER-016" ?
                            <CER016
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                                inoxAplikacije={this.props.inoxAplikacije}
                            />
                        : doorModel.value === "CER-017" ?
                            <CER017
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-018" ?
                            <CER018
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-019" ?
                            <CER019
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-020" ?
                            <CER020
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                                colorDecor2={this.props.colorDecor2}
                            />
                        : doorModel.value === "CER-021" ?
                            <CER021
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : doorModel.value === "CER-022" ?
                            <CER022
                                key={uuidv4()}
                                hisnaStevilka={this.props.hisnaStevilka}
                                doorWidth={this.props.doorWidth}
                                doorHeight={this.props.doorHeight}
                                glassModel={this.props.glassModel}
                                view={this.props.view}
                                handleDirection={this.props.handleDirection}
                                inoxAplikacije={this.props.inoxAplikacije}
                                inoxOdbojnaLetev={this.props.inoxOdbojnaLetev}
                            />
                        : null
                    }
                    
                    {
                        (this.props.view === "outside" && this.props.inoxOdbojnaLetev ) &&
                        // <rect id="inox" x="5" y={inoxY} className="inox" width={this.props.doorWidth-10} height="44"/>
                        <g style={{isolation: "isolate"}}>
                            <g style={{stroke: "rgba(0,0,0,.25)", strokeWidth: "1"}}>
                                <rect x="5" y={this.props.doorHeight-50}width={this.props.doorWidth-10} height="50" style={{fill: "url(#inox)"}}/>
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect x="5" y={28.34+19+this.props.doorHeight-50} width={this.props.doorWidth-10} height="2.66" style={{fill: "#d6d6d6"}}/>
                                </g>
                                <g style={{mixBlendMode: "multiply"}}>
                                    <rect x="5" y={this.props.doorHeight-50}width={this.props.doorWidth-10} height="2.66" style={{fill: "#e8e8e8"}}/>
                                </g>
                            </g>
                        </g>
                    }
                    <Handle
                        doorHeight={this.props.doorHeight}
                        handleModel={ this.props.view === "inside" ? this.props.handleModelInside : this.props.handleModelOutside }
                        defaultHandleX={doorModel.defaultHandleX}
                        citalecPrstnihOdtisov={this.props.citalecPrstnihOdtisov}
                        view={this.props.view}
                    />
                    { this.props.view === "outside" ?
                        <image xlinkHref={KeylockOut} x="30" y={this.props.doorHeight-958} height="50" width="50" />
                    :
                        <image xlinkHref={KeylockIn} x="30" y={this.props.doorHeight-958} height="50" width="50" />
                    }
                    { !this.props.skritiPanti && this.props.view ==="inside" && 
                    
                    <React.Fragment>
                        <rect
                            x={this.props.doorWidth-20}
                            y={200}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={200+65}
                            width={20}
                            height={80}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={200+65+80}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />

                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight/2-205}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight/2-205+65}
                            width={20}
                            height={80}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight/2-205+65+80}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />

                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight-410}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight-410+65}
                            width={20}
                            height={80}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                        <rect
                            x={this.props.doorWidth-20}
                            y={this.props.doorHeight-410+65+80}
                            width={20}
                            height={65}
                            rx="10"
                            style={{fill: "rgb(211,211,211)", stroke: "rgba(0, 0, 0, .85)", strokeWidth: "2"}}
                        />
                    </React.Fragment>
                    }

                </g>
            </svg>
        );
        
    }
}


export default Door;