import React, { Component } from 'react';
import "../vrata.css";

class CER001 extends Component {

    render() {

        return (
            <React.Fragment>
                { this.props.view === "outside" && 
                    <React.Fragment>
                        <g style={{isolation: "isolate"}}>
                            <rect
                                x={250}
                                y={5}
                                width={7}
                                height={this.props.doorHeight-20}
                                style={{fill: "rgba(0, 0, 0, 0.35)"}}
                            />
                            <rect
                                x={250+17}
                                y={5}
                                width={7}
                                height={this.props.doorHeight-20}
                                style={{fill: "rgba(246, 246, 246, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={130}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(0, 0, 0, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={130+17}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(246, 246, 246, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={(this.props.doorHeight/2-(7+25))}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(0, 0, 0, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={(this.props.doorHeight/2-(7+25))+17}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(246, 246, 246, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={this.props.doorHeight-130-50}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(0, 0, 0, 0.35)"}}
                            />
                            <rect
                                x={250+24}
                                y={this.props.doorHeight-130-50+17}
                                width={this.props.doorWidth-250-33}
                                height={7}
                                style={{fill: "rgba(246, 246, 246, 0.35)"}}
                            />
                        </g>
                        {this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={275} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                            :
                            <text textAnchor="end" x={this.props.doorWidth-20} y={275} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default CER001;