import React, { Component } from 'react';

class CER008 extends Component {

    render() {

        return (
            <React.Fragment>    
                {this.props.view === "outside" &&
                    this.props.view === "outside" && 
                        (this.props.handleDirection === "right" ? 
                            <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="start" x={20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                            </text>
                        :
                            <text textAnchor="end" x={this.props.doorWidth-20} y={200} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                                {this.props.hisnaStevilka}
                        </text>
                        )
                }
            </React.Fragment>
        );
    }
}

export default CER008;