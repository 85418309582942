import React, { Component } from 'react';
import stekloProsojnoNotri from "../../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotri from "../../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunaj from "../../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunaj from "../../glass/satinirano-steklo-zunaj-nr.jpg";

class CER004 extends Component {

    render() {

        return (
            <React.Fragment>
                <clipPath id="fillGlassPath">
                    <rect
                        x={this.props.doorWidth/2-100}
                        y={this.props.doorHeight/2-700}
                        width={200}
                        height={1400}
                    />
                </clipPath>
                <clipPath id="fillGlassPath2">
                    <rect
                        x={this.props.doorWidth/2-100+20}
                        y={this.props.doorHeight/2-700+20}
                        width={200-40}
                        height={1400-40}
                    />
                </clipPath>

                {this.props.glassModel === "04-1" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "04-1" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                {this.props.glassModel === "04-2" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "04-2" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                    </React.Fragment>
                }
                {this.props.glassModel === "04-3" && this.props.view === "outside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoNotri} />
                        <image style={{WebkitClipPath: "url(#fillGlassPath2)", clipPath: "url(#fillGlassPath2)"}} height="100%" xlinkHref={stekloSatiniranoNotri} />
                    </React.Fragment>
                }
                {this.props.glassModel === "04-3" && this.props.view === "inside" &&
                    <React.Fragment>
                        <image style={{WebkitClipPath: "url(#fillGlassPath)", clipPath: "url(#fillGlassPath)"}} height="100%" xlinkHref={stekloProsojnoZunaj} />
                        <image style={{WebkitClipPath: "url(#fillGlassPath2)", clipPath: "url(#fillGlassPath2)"}} height="100%" xlinkHref={stekloSatiniranoZunaj} />
                    </React.Fragment>
                }
                
                
                {this.props.view === "outside" && 
                    (this.props.handleDirection === "right" ? 
                        <text transform={this.props.handleDirection === "left" ? "scale(1,1)" : `scale(-1,1) translate(-${this.props.doorWidth} 0)`} textAnchor="middle" x={this.props.doorWidth/2} y={225} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    :
                        <text textAnchor="middle" x={this.props.doorWidth/2} y={225} fontSize="150px" fontFamily="Arial, Helvetica, sans-serif" style={{fill: "url(#inox)"}}>
                            {this.props.hisnaStevilka}
                        </text>
                    )
                }
            </React.Fragment>
        );
    }
}

export default CER004;