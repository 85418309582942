import React, { Component } from 'react';
import stekloProsojnoNotriLeft from "../glass/prosojno-steklo-notri-nr.jpg";
import stekloSatiniranoNotriLeft from "../glass/satinirano-steklo-notri-nr.jpg";
import stekloProsojnoZunajLeft from "../glass/prosojno-steklo-zunaj-nr.jpg";
import stekloSatiniranoZunajLeft from "../glass/satinirano-steklo-zunaj-nr.jpg";
import { v4 as uuidv4 } from 'uuid';

class FrameGlassLeft extends Component {

    render() {

        var newyf = this.props.doorHeight/1800;
        var razlikaVisine = this.props.doorHeight - 1800;

        const lcer003 = [];

        for (let e = 0; e <= 4; e++ ) {
            (lcer003.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+400+e*((this.props.doorHeight-900)/4)}
                    width={this.props.glassDimensions}
                    height={100}
                />
            ))
        }

        const lcer005 = [];

        for (let i = 1; i <= 12; i++ ) {
            lcer005.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+50+i*((this.props.doorHeight-((this.props.inoxOdbojnaLetev && this.props.view === "outside") ? 150 : 100))/13)}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        }

        const lcer006 = [];

        for (let i = 1; i <= 4; i++ ) {
            lcer006.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={i*(this.props.doorHeight/5)+100}
                    width={this.props.glassDimensions}
                    height={17}
                />
            )
        };

        const lcer011 = [];

        for (let e = 1; e <= 4; e++ ) {
            lcer011.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+(this.props.doorHeight-1400)/2+(e)*(264+20)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const lcer010 = [];

        for (let e = 1; e <= 4; e++ ) {
            lcer010.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+30+(this.props.doorHeight-1400)/2+(e)*(264+20)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        }

        const lcer012 = [];

        for (let e = 1; e <= 5; e++ ) {
            lcer012.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+(this.props.doorHeight/2)-3*250+e*250+20}
                    width={this.props.glassDimensions}
                    height={17}
                />
            )
        };

        const lcer014 = [];

        for (let i = 1; i <= 5; i++ ) {
            lcer014.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+30+i*(this.props.doorHeight/6)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const lcer016 = [];

        for (let e = 1; e <= 5; e++ ) {
            lcer016.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+e*233}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        }

        const lcer017 = [];

        for (let e = 1; e <= 6; e++ ) {
            lcer017.push(
                <React.Fragment key={uuidv4()}>
                    <rect
                        x={this.props.x}
                        y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1500)/2 : (this.props.doorHeight-1500)/2)+e*225}
                        width={this.props.glassDimensions}
                        height={25}
                    />
                </React.Fragment>
            )
        };

        const lcer018 = [];
        var visinaStekla = 1400;
        this.props.doorHeight >= 2300 ? visinaStekla = 1600 : visinaStekla = 1400

        for (let e = 1; e <= 5; e++ ) {
            lcer018.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-visinaStekla)/2 : (this.props.doorHeight-visinaStekla)/2)+e*(visinaStekla/6)}
                    width={this.props.glassDimensions}
                    height={20}
                    rx="10"
                />
            )
        };

        const lcer019 = [];

        for (let e = 0; e <= 4; e++ ) {
            lcer019.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+440+e*((this.props.doorHeight-900)/4)}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        };

        const lcer020 = [];

        for (let e = 1; e <= 4; e++ ) {
            lcer020.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1600)/2 : (this.props.doorHeight-1600)/2)+(e)*(325.5)}
                    width={this.props.glassDimensions}
                    height={15}
                />
            )
        };

        const lcer022 = [];

        for (let e = 1; e <= 4; e++ ) {
            lcer022.push(
                <rect
                    key={uuidv4()}
                    x={this.props.x}
                    y={this.props.y+25-300+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1100)/2 : (this.props.doorHeight-1100)/2) + e*300+90}
                    width={this.props.glassDimensions}
                    height={20}
                />
            )
        }

        return (
            <React.Fragment>
                <clipPath id="Lf_main">
                    <rect
                        x={this.props.x}
                        y={this.props.y}
                        width={this.props.glassDimensions}
                        height={this.props.doorHeight}
                    />
                </clipPath>
                <clipPath id="LCER-001">
                    <rect
                        x={this.props.x}
                        y={this.props.y+130}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                    <rect
                        x={this.props.x}
                        y={this.props.y+(this.props.doorHeight/2)}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                    <rect
                        x={this.props.x}
                        y={this.props.y+this.props.doorHeight-130}
                        width={this.props.glassDimensions}
                        height={20}
                    />
                </clipPath>
                <clipPath id="LCER-002">
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+327.1}
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+844.4} 
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                    <rect
                        x={this.props.x}
                        y={(razlikaVisine * (newyf) / 2) + this.props.y+30+1359.4}
                        className="izrez-inox"
                        width={this.props.glassDimensions}
                        height="20"
                    />
                </clipPath>
                <clipPath id="LCER-003">
                    {lcer003}
                </clipPath>
                <clipPath id="LCER-005">
                    {lcer005}
                </clipPath>
                <clipPath id="LCER-006">
                    {lcer006}
                </clipPath>
                <clipPath id="LCER-010">
                    {lcer010}
                </clipPath>
                <clipPath id="LCER-011">
                    {lcer011}
                </clipPath>
                <clipPath id="LCER-012">
                    {lcer012}
                </clipPath>
                <clipPath id="LCER-014">
                    {lcer014}
                </clipPath>
                <clipPath id="LCER-015">
                    <rect
                        x={this.props.x}
                        y={this.props.y+25+(this.props.inoxOdbojnaLetev ? ((this.props.doorHeight-50)-1400)/2 : (this.props.doorHeight-1400)/2)+280}
                        width={this.props.glassDimensions}
                        height={25}
                    />
                </clipPath>
                <clipPath id="LCER-016">
                    {lcer016}
                </clipPath>
                <clipPath id="LCER-017">
                    {lcer017}
                </clipPath>
                <clipPath id="LCER-018">
                    {lcer018}
                </clipPath>
                <clipPath id="LCER-019">
                    {lcer019}
                </clipPath>
                <clipPath id="LCER-020">
                    {lcer020}
                </clipPath>
                <clipPath id="LCER-022">
                    {lcer022}
                </clipPath>

                { this.props.view === "outside" ?
                    <>                                
                        {this.props.glassFrameModel === "f_satinirano" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_prosojno" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_01" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-001)", clipPath: "url(#LCER-001)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_02" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-002)", clipPath: "url(#LCER-002)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_03" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-003)", clipPath: "url(#LCER-003)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_02" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-002)", clipPath: "url(#LCER-002)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_05" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-005)", clipPath: "url(#LCER-005)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {(this.props.glassFrameModel === "f_06" || this.props.glassFrameModel === "f_07")  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-006)", clipPath: "url(#LCER-006)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_10"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-010)", clipPath: "url(#LCER-010)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_11"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-011)", clipPath: "url(#LCER-011)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_12"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-012)", clipPath: "url(#LCER-012)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_14"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-014)", clipPath: "url(#LCER-014)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_15"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-015)", clipPath: "url(#LCER-015)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_16"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-016)", clipPath: "url(#LCER-016)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_17"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-017)", clipPath: "url(#LCER-017)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_18"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-018)", clipPath: "url(#LCER-018)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_19"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-019)", clipPath: "url(#LCER-019)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_20"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-020)", clipPath: "url(#LCER-020)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_22"  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoNotriLeft} />
                               <image style={{WebkitClipPath: "url(#LCER-022)", clipPath: "url(#LCER-022)"}} height="100%" xlinkHref={stekloProsojnoNotriLeft} />
                            </React.Fragment>
                        }
                    </>
                :
                    <>
                        {this.props.glassFrameModel === "f_satinirano" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_prosojno" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_01" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#CLER-001)", clipPath: "url(#LCER-001)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_02" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-002)", clipPath: "url(#LCER-002)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_03" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-003)", clipPath: "url(#LCER-003)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_05" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-005)", clipPath: "url(#LCER-005)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {(this.props.glassFrameModel === "f_06" || this.props.glassFrameModel === "f_07")  &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-006)", clipPath: "url(#LCER-006)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_10" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-010)", clipPath: "url(#LCER-010)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_11" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-011)", clipPath: "url(#LCER-011)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_12" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-012)", clipPath: "url(#LCER-012)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_14" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-014)", clipPath: "url(#LCER-014)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_15" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-015)", clipPath: "url(#LCER-015)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_16" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-016)", clipPath: "url(#LCER-016)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_17" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-017)", clipPath: "url(#LCER-017)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_18" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-018)", clipPath: "url(#LCER-018)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_19" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-019)", clipPath: "url(#LCER-019)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_20" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-020)", clipPath: "url(#LCER-020)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                        {this.props.glassFrameModel === "f_22" &&
                            <React.Fragment>
                                <image style={{WebkitClipPath: "url(#Lf_main)", clipPath: "url(#Lf_main)"}} height="100%" xlinkHref={stekloSatiniranoZunajLeft} />
                                <image style={{WebkitClipPath: "url(#LCER-022)", clipPath: "url(#LCER-022)"}} height="100%" xlinkHref={stekloProsojnoZunajLeft} />
                            </React.Fragment>
                        }
                    </>
                }
            </React.Fragment>
        );
    }
}

export default FrameGlassLeft;